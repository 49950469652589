import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';

import { ApplicantService } from 'src/app/services/applicant.service';
import { ExpandableRowComponent } from '../../home/expandable-row/expandable-row.component';
import { DialogData } from '../company-sidebar/companysidebar.component';

@Component({
  selector: 'app-send-whatsapp-dialog',
  templateUrl: './send-whatsapp-dialog.component.html',
  styleUrls: ['./send-whatsapp-dialog.component.scss'],
})
export class SendWhatsappDialogComponent implements OnInit {
  returnValue: string;
  applier_phone: any;
  encoded_value: any;
  cols: number;
  rows: number;
  checkStatus: any;
  match_ids: number[] | null = null;
  isLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private applicantService: ApplicantService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ExpandableRowComponent>
  ) {
    this.returnValue = data['returnValue'];
    this.applier_phone = data['applier_phone'];
    this.checkStatus = data['checkStatus'];
    this.match_ids = data?.match_ids ?? [];
  }

  ngOnInit() {
    if (+this.checkStatus === 7) {
      this.cols = 15;
      this.rows = 10;
    } else {
      this.cols = 90;
      this.rows = 25;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  encode(name) {
    return encodeURIComponent(name);
  }

  sendJobOffer() {
    this.isLoading = true;
    const user_agent = JSON.parse(localStorage.getItem('agentData'));
    this.applicantService
      .sendJobOffer({
        user_agent,
        match_ids: this.match_ids,
      })
      .subscribe({
        next: (val) =>
          this._snackBar.open(
            'Whatsapp Nachricht via Superchat gesendet',
            'OK',
            {
              duration: 5000,
            }
          ),
        error: () =>
          this._snackBar.open('Es ist etwas schief gelaufen', 'OK', {
            duration: 5000,
          }),
      });
  }
}
