import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatRippleModule } from '@angular/material/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import {
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/authentication/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/common/header/header.component';
import { AdminLoginComponent } from './components/authentication/admin-login/admin-login.component';
import { JobHomeComponent } from './components/job-home/job-home.component';
import {
  HistoryMatchChannelAbbreviationsById,
  ConstantLabelPipe,
} from './pipes/abbreviations.pipe';
import { ExpandableRowComponent } from './components/home/expandable-row/expandable-row.component';
import { environment } from 'src/environments/environment';
import { AgentCompanyDashboardComponent } from './components/company/agent-company-dashboard/agent-dashboard.component';
import { CompanyRegisterComponent } from './components/authentication/company-register/company-register.component';
import { TagComponent } from './components/common/tag/tag.component';
import { SearchJobComponent } from './components/search-job/search-job.component';
import { MY_DATE_FORMATS } from './my-date-formats';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { MatchComponent } from './components/match/match.component';
import { JobDeleteDialogComponent } from './components/common/job-delete-dialog/job-delete-dialog.component';
import { AddCompanyUserComponent } from './components/common/add-company-user/add-company-user.component';
import { AgentDetailsDialogComponent } from './components/common/agent-details-dialog/agent-details-dialog.component';
import { SendWhatsappDialogComponent } from './components/common/send-whatsapp-dialog/send-whatsapp-dialog.component';
import { ExcelExportDialogComponent } from './components/common/excel-export-dialog/excel-export-dialog.component';
import { AddLabelComponent } from './components/match/history/add-label/add-label.component';
import { PostingsComponent } from './components/postings/postings.component';
import { MobileUserComponent } from './components/common/mobile-user/mobile-user.component';
import { CompanyHomeComponent } from './components/company-home/company-home.component';
import { CompanyDetailComponent } from './components/company-home/company-detail/company-detail.component';
import { KolkovEditorComponent } from './components/common/form/kolkov-editor/kolkov-editor.component';
import { RowSelecterComponent } from './components/common/table/row-selecter/row-selecter.component';
import { SheetComponent } from './components/common/table/row-selecter/sheet/sheet.component';
import { MatchHistoryComponent } from './components/match/history/history.component';
import { CompanySidebarComponent } from './components/common/company-sidebar/companysidebar.component';
import { JobSidebarComponent } from './components/common/job-sidebar/job-sidebar.component';
import { JobUploadCsvComponent } from './components/common/job-upload-csv/job-upload-csv.component';
import { JobPostingSidebarComponent } from './components/common/job-posting-sidebar/job-posting-sidebar.component';
import { HistorySidebarComponent } from './components/common/history-sidebar/history-sidebar.component';
import { ApplierSidebarComponent } from './components/common/applier-sidebar/applier-sidebar.component';
import { HealthFacilityFormComponent } from './components/common/health-facility-form/health-facility-form.component';
import { JobUploadContentComponent } from './components/common/job-upload-csv/job-upload-content/job-upload-content.component';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.MS_clientId,
      redirectUri: environment.MS_redirectUri,
      authority: environment.MS_authority,
      postLogoutRedirectUri: environment.MS_postLogoutRedirectUri,
    },
  });
}

export function rootLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    AdminLoginComponent,
    JobHomeComponent,
    ConstantLabelPipe,
    ExpandableRowComponent,
    AgentCompanyDashboardComponent,
    CompanyRegisterComponent,
    TagComponent,
    MatchHistoryComponent,
    SearchJobComponent,
    MyProfileComponent,
    HistoryMatchChannelAbbreviationsById,
    MatchComponent,
    CompanySidebarComponent,
    JobSidebarComponent,
    JobUploadCsvComponent,
    JobUploadContentComponent,
    JobPostingSidebarComponent,
    HistorySidebarComponent,
    ApplierSidebarComponent,
    PostingsComponent,
    MobileUserComponent,
    CompanyHomeComponent,
    CompanyDetailComponent,
    KolkovEditorComponent,
    RowSelecterComponent,
    SheetComponent,
    JobDeleteDialogComponent,
    AddCompanyUserComponent,
    AgentDetailsDialogComponent,
    SendWhatsappDialogComponent,
    ExcelExportDialogComponent,
    AddLabelComponent,
    HealthFacilityFormComponent,
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    AppRoutingModule,
    MatExpansionModule,
    MatTabsModule,
    NgxChartsModule,
    MatFormFieldModule,
    MatBottomSheetModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatCardModule,
    MatRippleModule,
    MsalModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTableModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatDividerModule,
    ScrollingModule,
    ClipboardModule,
    MatMenuModule,
    MatTooltipModule,
    AgmCoreModule.forRoot({
      apiKey: environment.AGM_CORE_API_KEY,
    }),
    NgxDaterangepickerMd.forRoot(),
    HttpClientModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    OverlayModule,
    MatGridListModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatSnackBarModule,
    MatListModule,
    BrowserAnimationsModule,
    GooglePlaceModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatMomentDateModule,
    MatProgressBarModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
    MatDialogModule,
    Ng5SliderModule,
    AngularEditorModule,
    InfiniteScrollModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxTippyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: rootLoaderFactory,
        deps: [HttpClient],
      },
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
  ],
  providers: [
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    MsalService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
