import { Clipboard } from '@angular/cdk/clipboard';
import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, take } from 'rxjs/operators';

import { Constants } from 'src/app/models/abbreviation.model';
import { actors, getActor } from 'src/app/models/abbreviations/applierStatus';
import { getCommuncationLabel } from 'src/app/models/abbreviations/communicationsTypes';
import { HistoryMatch } from 'src/app/models/history-match.model';
import { ApplicantService } from 'src/app/services/applicant.service';
import { ConstItem } from 'src/app/services/constants.service';
import { HistoryMatchService } from 'src/app/services/history-match.service';
import { AbbreviationModel } from '../../../models/abbreviation.model';
import { SendWhatsappDialogComponent } from '../../common/send-whatsapp-dialog/send-whatsapp-dialog.component';

@Component({
  selector: 'app-match-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class MatchHistoryComponent implements OnInit {
  @Input() applierId: number;
  @Input() match: any;
  @Output() update = new EventEmitter<any>();
  @Output() doClose = new EventEmitter<any>();
  @Output() refreshApplier = new EventEmitter<any>();
  @Output() updateParentNeedAction = new EventEmitter<any>();
  communcationLabel = getCommuncationLabel;
  getActorLabel = getActor;
  icn: string;
  chnlnm: string;
  showSpinner = false;
  historyForm: FormGroup;
  statis: any;
  now: any = new Date();
  need_action = false;
  protected = false;
  status: any = [];
  channels: any = [];
  labelIds: number[] = [];
  isDueDate = true;
  returnValue: string;
  selectedMatchStage: string;
  dropdownSettings: any = {};
  selectedFlag = [];
  lookupFields: string[] = [
    'salary',
    'start_date',
    'interview_date',
    'shadowing_date',
    'termination_date',
    'termination_reason',
    'terminated_by',
    'rejection_reason',
    'rejected_by',
  ];

  histories: any;
  historiesIsEmpty = false;
  constructor(
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private historyMatchService: HistoryMatchService,
    public dialog: MatDialog,
    private applicantService: ApplicantService,
    private clipboard: Clipboard,
    public constants: Constants
  ) {}

  /**
   * DEFAULT METHOD FOR ANGULAR AUTOLOADED MATHOD
   */
  ngOnInit(): void {
    this.initForm();
    const baseDropDownSettigs = {
      singleSelection: true,
      idField: 'id',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 6,
      allowSearchFilter: false,
      enableCheckAll: false,
    };
    this.dropdownSettings = {
      ...baseDropDownSettigs,
      singleSelection: false,
    };
  }

  /**
   * INITIALIZE FORM WHEN PAGE ARE LOADED
   */
  initForm() {
    this.selectedMatchStage = this.match?.stage;
    this.need_action = this?.match?.need_action;
    this.protected = this?.match?.protected;
    this.channels = new AbbreviationModel().getHistoryMatchChannel();
    Number(this.match?.id);
    this.historyForm = this._formBuilder.group({
      match_id: [this.match?.id],
      content: [''],
      channel: ['', Validators.required],
      status_id: ['', Validators.required],
      due_date: [''],
      costum_Date: [''],
    });
    this.getMatchFlags();
    this.getStatus();
    this.getMatchHistory();
  }
  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  setDifference(setA, setB) {
    const _difference = new Set(setA);
    for (const elem of setB) {
      _difference.delete(elem);
    }
    return _difference;
  }

  getFieldLabel(changes: {
    field: string;
    old: any[] | number;
    new: any[] | number;
  }) {
    const mappings = {
      salary: 'Gehalt',
      start_date: 'Einstiegsdatum',
      interview_date: 'Interviewdatum',
      shadowing_date: 'Hospitationsdatum',
      termination_date: 'Kündigungsdatum',
      termination_reason: 'Kündigungsgrund',
      terminated_by: 'Gekündigt von',
      rejection_reason: 'Ablehnungsgrund',
      rejected_by: 'Abgelehnt von',
    };

    return mappings[changes.field] ?? null;
  }

  companyFeedbackCount(feedback: Record<string, any> = {}): number {
    return Object.values(feedback).filter(Boolean).length;
  }

  matchFlagLabels(flags: string[]): string {
    return (flags || [])
      .flatMap(val => {
        return this.constants.getMatchFlag(val)?.label ?? '';
      })
      .join(' , ');
  }

  updateNeedAction(need_action: boolean) {
    this.historyMatchService
      .updateHistoryMatchlabel(this.match?.id, {
        need_action,
      })
      .subscribe({
        next: resp => {
          this.updateParentNeedAction.emit({
            match_id: this.match?.id,
            need_action,
          });
          this._snackBar.open('Need Action Updated', 'OK', { duration: 5000 });
        },
        error: e => {
          this._snackBar.open('Something Went wrong', 'OK', { duration: 5000 });
        },
      });
  }

  toggleProtectedOnMatch(isProtected: boolean) {
    this.historyMatchService
      .updateHistoryMatchlabel(this.match?.id, {
        protected: isProtected,
      })
      .subscribe({
        next: resp => {
          const message = isProtected
            ? 'Match has been protected'
            : 'Match is no longer protected';
          this._snackBar.open(message, 'OK', { duration: 5000 });
        },
        error: e => {
          this._snackBar.open('Something Went wrong', 'OK', { duration: 5000 });
        },
      });
  }

  getStatusText(status): string {
    return this.constants.getMatchStage(status)?.label ?? '';
  }

  flagLabel(log: any) {
    const newFlags = log?.details?.new_flags ?? [];
    const oldFlags = log?.details?.old_flags ?? [];

    if (
      newFlags.length == oldFlags.length &&
      log.actor === actors.SYSTEM.id &&
      log.type === 'FLAG'
    ) {
      const flags = this.matchFlagLabels([
        ...this.setDifference(newFlags, oldFlags),
      ] as string[]);
      return `Das System hat den Flag zu "${flags}" geändert`;
    }

    if (newFlags.length > oldFlags.length) {
      const flags = this.matchFlagLabels([
        ...this.setDifference(newFlags, oldFlags),
      ] as string[]);

      return ` Das  Flag "${flags}" wurde gesetzt `;
    } else {
      const flags = this.matchFlagLabels([
        ...this.setDifference(oldFlags, newFlags),
      ] as string[]);

      return `Das Flag "${flags}" wurde entfernt`;
    }
  }

  statusChangeLabel(log) {
    if (!log?.old_status?.length && log?.status)
      return 'Ein neues Match wurde gefunden';
    if (log.actor === actors.SYSTEM.id)
      return `Das System hat den Status zu "${this.getStatusText(
        log?.status
      )}" geändert`;

    return `${log?.created_by?.first_name} ${
      log?.created_by?.last_name || log?.user?.last_name
    }  (${
      getActor(log?.actor)?.label
    })  hat den Status zu "${this.getStatusText(
      log?.new_status || log?.status
    )}" geändert`;
  }

  /**
   *
   * @param days ADD DAYS IN TODAY DATE AND SET INTO NEW DUE_DATE
   */
  updateDate(days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    this.historyForm.controls['due_date'].clearValidators();
    if (days) {
      this.historyForm.patchValue({
        due_date: date,
      });
      this.isDueDate = true;
      this.historyForm.controls['due_date'].setValidators([
        Validators.required,
      ]);
    } else {
      this.historyForm.patchValue({
        due_date: null,
      });
      this.isDueDate = false;
      this.historyForm.controls['due_date'].clearValidators();
    }
  }

  getMatchFlags() {
    this.constants
      .getList('match_flag')
      .pipe(
        switchMap(matchFlags => {
          return this.historyMatchService.getMatchFlags(this.match?.id).pipe(
            map((presectedFlags: Array<any>) => {
              this.selectedFlag = presectedFlags.map(({ flag }) => {
                return matchFlags.find((i: ConstItem) => i.id == flag);
              });
            })
          );
        }),
        take(1)
      )
      .subscribe({
        error: errorRes => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        },
      });
  }

  getMatchHistory() {
    this.historyMatchService.getMatchHistoryHistory(this.match?.id).subscribe(
      (data: Array<any>) => {
        const histories = (data ?? []).reduce((accumulator, currentValue) => {
          const key = new Date(currentValue?.applier?.created_at)
            .toISOString()
            .split('T')[0];
          if (!accumulator[key]) accumulator[key] = [];
          accumulator[key].push(currentValue);
          return accumulator;
        }, {});

        this.histories = histories;
        this.historiesIsEmpty = !Object.keys(histories).length;
        this.showSpinner = false;
      },
      errorRes => {
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      }
    );
  }

  onFlagSelect($event) {
    this.historyMatchService
      .createMatchFlags({
        flag: $event.id,
        match: this.match?.id,
      })
      .subscribe(response => {
        this.getMatchHistory();
        this._snackBar.open('flag created successfully', 'OK', {
          duration: 3000,
        });
      });
  }

  onFlagDeSelect($event) {
    this.historyMatchService
      .deletedMatchFlags(this.match?.id, { flag: $event.id })
      .subscribe(response => {
        this.getMatchHistory();
        this._snackBar.open('flag removed successfully', 'OK', {
          duration: 3000,
        });
      });
  }

  /**
   * ADD MATCH HISTORY
   */
  submitHistory() {
    const newdate = new Date(this.historyForm.value.due_date);
    const due_date =
      newdate.getFullYear() +
      '-' +
      ((newdate || new Date()).getMonth() + 1) +
      '-' +
      newdate.getDate();

    const costum_dt = this.historyForm.value.costum_Date
      ? new Date(this.historyForm.value.costum_Date)
      : null;
    const costum_date = costum_dt
      ? costum_dt.getFullYear() +
        '-' +
        ((costum_dt || new Date()).getMonth() + 1) +
        '-' +
        costum_dt.getDate()
      : null;

    if (this.historyForm.valid == true) {
      const postData = new HistoryMatch(
        this.historyForm.value.content,
        Number(this.match?.id),
        this.historyForm.value.status_id,
        this.historyForm.value.channel,
        this.isDueDate ? due_date : null,
        costum_date ? costum_date : null
      );
      this.showSpinner = true;
      this.historyMatchService.addHistoryMatchData(postData).subscribe(
        (response: any) => {
          this.getMatchHistory();
          this.refreshApplier.emit();
          this.historyForm.reset();
          this.update.emit(response);
        },
        errorRes => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
    }
  }

  updateMatchStatus() {
    const data = {
      stage: this.selectedMatchStage,
    };

    this.historyMatchService
      .updateHistoryMatchlabel(this.match?.id, data)
      .subscribe(res => this.getMatchHistory());
  }

  /**
   * GET STATUS USING API
   */
  getStatus() {
    if (this.status?.length == 0) {
      this.showSpinner = true;
      this.historyMatchService.getHistoryMatchStatus().subscribe(
        (response: any) => {
          this.status = response?.results;
        },
        errorRes => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
    }
  }

  /**
   *
   * @param icon SET
   * @param name
   */
  changeIconName(event: MatOptionSelectionChange, icon: string, name: string) {
    if (!event.isUserInput) return;
    this.icn = icon;
    this.chnlnm = name;
  }

  sendMatches(
    channel_id: number,
    applier_id: number,
    applier_phone: string,
    status_id: number
  ) {
    let postData = {
      match_ids: [],
      status: null,
      note: '',
      note_bool: false,
      applier_id: applier_id,
      channel: channel_id === 7 ? 'whatsapp' : channel_id === 8 ? 'mail' : '',
      status_id: status_id,
      model_name: 'StatusMatchItem',
    };
    this.applicantService.sendMatches(applier_id, postData).subscribe(
      res => {
        this.returnValue = '';
        if (channel_id === 7 && res['output']) {
          this.returnValue = res['output'];
          this.addCompanyDialog(channel_id, applier_phone);
          this.clipboard.copy(this.returnValue);
        }
        const snakMessage =
          channel_id === 7 && res['output']
            ? this.translate.instant('message.copied_to_clipboard')
            : channel_id === 8
            ? this.translate.instant('message.email_sent')
            : 'enthalten nicht verfügbar';
        this._snackBar.open(snakMessage, '', {
          duration: 5000,
        });
      },
      error => {
        if (error !== null && error !== 'undefined') {
          this._snackBar.open(error.replace('message', ''), '', {
            duration: 5000,
          });
        } else {
          this._snackBar.open(
            this.translate.instant('message.something_wrong'),
            '',
            { duration: 5000 }
          );
        }
        // this.buttonDisable = false;
      }
    );
  }

  addCompanyDialog(channel_id: number, applier_phone: string) {
    const dialogRef = this.dialog.open(SendWhatsappDialogComponent, {
      data: {
        returnValue: this.returnValue,
        applier_phone: applier_phone,
        checkStatus: channel_id,
      },
      panelClass: 'myClass',
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  clearDate(event) {
    event.stopPropagation();
    this.historyForm.patchValue({ costum_Date: null });
  }
}
