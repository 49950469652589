import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HistoryMatchService } from 'src/app/services/history-match.service';
import { MatchComponent } from '../../match/match.component';
import { DialogData } from '../company-sidebar/companysidebar.component';

@Component({
  selector: 'app-excel-export-dialog',
  templateUrl: './excel-export-dialog.component.html',
  styleUrls: ['./excel-export-dialog.component.scss'],
})
export class ExcelExportDialogComponent implements OnInit {
  dropdown = [];
  selectedType: [] = [];
  name: [''];
  dropdownSettings: IDropdownSettings;
  singleSelectionDropdownSettings: IDropdownSettings;
  dataSource: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<MatchComponent>,
    private historyService: HistoryMatchService
  ) {
    this.dataSource = data['dataSource'];
  }

  ngOnInit(): void {
    this.dropdown = [
      'Applier Name',
      'Forwarding Date',
      'Applier Education',
      'Applier CareType',
      'Company Name',
      'City',
    ];
  }

  onSave() {
    let typeArray = [];
    const items = {
      'Applier Name': 'applier__name',
      'Forwarding Date': 'forwarding_date',
      'Company Name': 'company__name',
      City: 'job__city',
      'Applier Education': 'applier__education',
      'Applier CareType': 'applier__care_type',
    };
    for (let item of this.selectedType) {
      const value: string = items[item];
      if (value) {
        typeArray.push(value);
      }
    }
    this.historyService
      .getExcelExportData(typeArray, this.dataSource)
      .subscribe((res: any) => {
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
