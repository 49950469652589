import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateConfigService {
  constructor(private translateService: TranslateService) {
    this.translateService.use('de');
    if (sessionStorage.getItem('lang') !== 'null') {
      sessionStorage.setItem('lang', sessionStorage.getItem('lang'));
    }
  }

  changeLanguage(lang: string) {
    this.translateService.use(lang);
    this.translateService.setDefaultLang(lang);
    sessionStorage.setItem('lang', lang);
  }
}
