import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

export interface HealthFacility {
  id?: number;
  company_id?: number;
  company?: any;
  street?: string;
  house_number?: string;
  postcode?: string;
  city?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
}

@Injectable({
  providedIn: 'root',
})
export class HealthFacilityService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  createHealthFacility({
    companyId,
    healthFacility,
  }: {
    companyId: number;
    healthFacility: HealthFacility;
  }): Observable<HealthFacility> {
    const httpOptions = this.authService.getHTTPOption();
    return this.http.post<Partial<HealthFacility>>(
      `${environment.BASE_URL}${environment.PATH}company/${companyId}/health-facilities/`,
      healthFacility,
      httpOptions
    );
  }
}
