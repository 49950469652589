<div *ngIf="applierId" class="wrapper">
  <div class="flags-wrapper">
    <ng-multiselect-dropdown
      placeholder="{{ 'match.flags' | translate }}"
      [settings]="dropdownSettings"
      [(ngModel)]="selectedFlag"
      [data]="constants.getList('match_flag') | async"
      (onSelect)="onFlagSelect($event)"
      (onDeSelect)="onFlagDeSelect($event)"
      #multiSelect
      style="display: block; width: 40%; margin-block-start: 10px"
    >
    </ng-multiselect-dropdown>
    <div
      [class.opacify]="companyFeedbackCount(match.company_feedback) == 0"
      [ngxTippy]="
        companyFeedbackCount(match.company_feedback) != 0
          ? tooltipHTMLTemplate
          : null
      "
      tippyClassName="match_company_feedback"
      [tippyProps]="{
        placement: 'bottom',
        theme: 'light'
      }"
    >
      {{ companyFeedbackCount(match.company_feedback) }} Statusdetails
      <mat-icon>info_outline</mat-icon>
    </div>

    <template #tooltipHTMLTemplate>
      <div>
        <b *ngIf="match.company_feedback.interview_date">{{
          'match-history.statusDetails.interviewDate' | translate
        }}</b>
        <p>
          <span>
            {{ match.company_feedback.interview_date | date }}
          </span>
        </p>
      </div>

      <div>
        <b *ngIf="match.company_feedback.shadowing_date">{{
          'match-history.statusDetails.shadowingDate' | translate
        }}</b>
        <p>
          <span>{{ match.company_feedback.shadowing_date | date }}</span>
        </p>
      </div>

      <div *ngIf="match.company_feedback.salary">
        <b class="">{{ 'match-history.statusDetails.salary' | translate }}</b>
        <p>
          <span>{{ match.company_feedback.salary }}</span>
        </p>
      </div>

      <div *ngIf="match.company_feedback.start_date">
        <b>{{ 'match-history.statusDetails.startDate' | translate }}</b>
        <p>
          <span>
            {{ match.company_feedback.start_date | date }}
          </span>
        </p>
      </div>

      <div *ngIf="match.company_feedback.terminated_by">
        <b>{{ 'match-history.statusDetails.terminatedBy' | translate }}</b>
        <p>
          <span>
            {{ match.company_feedback.terminated_by }}
          </span>
        </p>
      </div>

      <div *ngIf="match.company_feedback.termination_date">
        <b>{{ 'match-history.statusDetails.terminationDate' | translate }}</b>
        <p>
          <span>
            {{
              match.company_feedback.termination_date
                | localizedDate: 'dd.MM.yyyy'
            }}
          </span>
        </p>
      </div>

      <div *ngIf="match.company_feedback.termination_reason">
        <b>{{ 'match-history.statusDetails.terminationReason' | translate }}</b>
        <p>
          {{ match.company_feedback.termination_reason }}
        </p>
      </div>

      <div *ngIf="match.company_feedback.rejected_by">
        <b>{{ 'match-history.statusDetails.rejectedBy' | translate }}</b>
        <p class="card-line-value capitalize">
          <span>
            {{ match.company_feedback.rejected_by }}
          </span>
        </p>
      </div>

      <div *ngIf="match.company_feedback.rejection_reason">
        <b class="">{{
          'match-history.statusDetails.rejectionReason' | translate
        }}</b>
        <p>
          {{ match.company_feedback.rejection_reason }}
        </p>
      </div>
    </template>
  </div>

  <div class="phase_wrapper">
    <form class="company-status" (ngSubmit)="updateMatchStatus()">
      <mat-form-field>
        <mat-label>Phase</mat-label>
        <mat-select [(ngModel)]="selectedMatchStage" name="Status">
          <mat-option
            *ngFor="let stati of constants.getList('match_stage') | async"
            [value]="stati.id"
          >
            {{ stati?.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        style="margin-left: 16px"
      >
        {{ 'match-history.btn_send' | translate }}
      </button>
    </form>
    <div>
      <mat-checkbox
        [(ngModel)]="need_action"
        (change)="updateNeedAction($event.checked)"
        >Need Action?</mat-checkbox
      >

      <mat-checkbox
        [(ngModel)]="protected"
        (change)="toggleProtectedOnMatch($event.checked)"
        >Protected Match?</mat-checkbox
      >
    </div>
  </div>

  <mat-divider></mat-divider>

  <form
    style="margin-top: 50px"
    [formGroup]="historyForm"
    (ngSubmit)="submitHistory()"
  >
    <!--Content field-->

    <mat-form-field style="width: 68%">
      <mat-label>Kurzbeschreibung</mat-label>
      <input matInput formControlName="content" />
    </mat-form-field>
    <mat-form-field style="width: 28%">
      <mat-label translate>history.costum_date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="costum_Date" />
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon (click)="clearDate($event)"
          >clear</mat-icon
        >
      </mat-datepicker-toggle>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <!--Channel field-->
    <mat-form-field style="width: 27.5%">
      <mat-label>Kanal</mat-label>
      <mat-select formControlName="channel" class="channel">
        <!--Add this when formgroup is entered-->
        <mat-select-trigger class="mat-select-trigger-match">
          <mat-icon
            class="whatsapp-icon selected-icon"
            *ngIf="icn === 'whatsapp'; else elseBlock"
          >
          </mat-icon>
          <ng-template #elseBlock>
            <mat-icon class="selected-icon">{{ icn }}</mat-icon>
          </ng-template>

          &nbsp;{{ chnlnm }}
        </mat-select-trigger>
        <mat-option
          (onSelectionChange)="changeIconName($event, chnl.icon, chnl?.name)"
          *ngFor="let chnl of channels"
          [value]="chnl.id"
        >
          <mat-icon
            class="whatsapp-icon"
            *ngIf="chnl.icon === 'whatsapp'; else elseBlock"
          >
          </mat-icon>
          <ng-template #elseBlock>
            <mat-icon>{{ chnl.icon }}</mat-icon>
          </ng-template>
          {{ chnl.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!--Status get from backend-->
    <mat-form-field style="width: 27.5%">
      <mat-label>Template</mat-label>
      <mat-select formControlName="status_id">
        <mat-option
          *ngFor="let stati of status"
          [value]="stati.id"
          (click)="updateDate(stati?.due_days, $event)"
        >
          {{ stati?.status_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!--due date-->
    <mat-form-field style="width: 27.5%">
      <mat-label>nächste Aktion</mat-label>
      <input
        matInput
        formControlName="due_date"
        [matDatepicker]="picker2"
        [disabled]="!isDueDate"
      />
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

    <button
      type="submit"
      mat-flat-button
      color="primary"
      style="margin-left: 16px"
    >
      {{ 'match-history.btn_send' | translate }}
    </button>
  </form>

  <mat-divider></mat-divider>

  <app-add-label [matchId]="match?.id" [labelIds]="labelIds"></app-add-label>

  <mat-divider></mat-divider>

  <div
    *ngFor="let history of histories | keyvalue: originalOrder"
    class="histories"
  >
    <div class="card-title">
      Bewerbung:
      <time [datetime]="history.key">
        {{ history.key | date: 'dd.MM.yyyy' }}
      </time>
    </div>
    <mat-card *ngFor="let item of history.value; first as isFirst">
      <div class="card-image">
        <mat-icon
          class="whatsapp-icon"
          *ngIf="item?.details?.channel_id === '1'; else statusIcon"
        >
        </mat-icon>

        <ng-template #statusIcon>
          <mat-icon *ngIf="item?.old_status !== item?.status; else flagIcon">
            query_builder
          </mat-icon>
        </ng-template>

        <ng-template #flagIcon>
          <mat-icon *ngIf="item.type === 'FLAG'; else communicationIcon">
            flag
          </mat-icon>
        </ng-template>

        <ng-template #communicationIcon>
          <mat-icon *ngIf="item.type === 'COMMUNICATION'; else elseBlock">
            forum
          </mat-icon>
        </ng-template>

        <ng-template #elseBlock>
          <mat-icon
            >{{ item?.details?.channel_id | HistoryMatchChannelAbbreviations }}
          </mat-icon>
        </ng-template>
      </div>
      <div class="content">
        <mat-card-subtitle class="no-margin">
          {{ item?.created_at | date: 'dd.MM.yyyy, HH:mm' }}
          |
          <span
            matTooltip="erstellt von"
            *ngIf="item?.actor == 'SYSTEM'; else actor"
          >
            {{ 'System' }}
          </span>
          <ng-template #actor>
            <span>
              {{ item?.created_by?.first_name }}
              {{ item?.created_by?.last_name }}
              ({{ getActorLabel(item?.actor)?.label ?? '' }})
            </span>
          </ng-template>
        </mat-card-subtitle>
        <mat-card-content *ngIf="item.type === 'FLAG'; else fieldChange">
          {{ flagLabel(item) }}
        </mat-card-content>

        <ng-template #fieldChange>
          <mat-card-content
            *ngIf="item.type === 'CRUD' && item?.details?.changed; else status"
          >
            Die Statusdetails wurden bearbeitet
            <ul>
              <ng-container
                class="changed_field"
                *ngFor="let change of item?.details?.changed"
              >
                <li *ngIf="getFieldLabel(change)">
                  <i>
                    {{ getFieldLabel(change) }}
                  </i>
                  von

                  <span>
                    "{{ change?.old ? change?.old : 'keine Angabe' }}" zu "{{
                      change?.new ? change?.new : 'keine Angabe'
                    }}"
                  </span>
                </li>
              </ng-container>
            </ul>
          </mat-card-content>
        </ng-template>

        <ng-template #status>
          <mat-card-content
            *ngIf="item?.old_status !== item?.status; else communication"
          >
            {{ statusChangeLabel(item) }}
          </mat-card-content>
        </ng-template>

        <ng-template #communication>
          <mat-card-content
            *ngIf="item.type === 'COMMUNICATION'; else status_log"
          >
            Eine E-mail / WhatsApp Nachricht wurde verschickt:
            {{ communcationLabel(log?.details) }}
          </mat-card-content>
        </ng-template>

        <ng-template #status_log>
          <div *ngIf="item?.details?.status_id; else default">
            <mat-card-content
              class="{{ item?.content ? 'no-margin' : 'm3-margin' }}"
              >{{ item?.status_log?.status_name }}
            </mat-card-content>
          </div>
        </ng-template>

        <mat-card-subtitle class="less-margin">
          <span matTooltip="Ablaufdatum" *ngIf="item?.details?.due_date">{{
            item?.details?.due_date | date: 'dd.MM.yyyy, HH:mm'
          }}</span>
        </mat-card-subtitle>
        <mat-card-content class="less-margin">
          {{ item?.status?.status_name }}
          <ng-container *ngIf="item.costum_date"
            >({{ item.costum_date | date: 'dd.MM.yyyy, HH:mm' }})</ng-container
          >
        </mat-card-content>
        <mat-card-subtitle class="less-margin">
          {{ item?.note }}
        </mat-card-subtitle>
      </div>
    </mat-card>
  </div>

  <ng-container *ngIf="showSpinner">
    <ngx-skeleton-loader
      count="7"
      appearance="line"
      [theme]="{ 'height.px': 50 }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngIf="historiesIsEmpty">
    <h3 class="drawer-title" style="text-align: center; margin: 20px">
      {{ 'match-history.no_record' | translate }}
    </h3>
  </ng-container>
</div>
