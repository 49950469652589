<mat-drawer-container class="container" [hasBackdrop]="true">
  <!-- Sidenav start -->
  <mat-drawer
    #drawer
    class="sidenav"
    position="end"
    [mode]="'side'"
    (closedStart)="startClose()"
  >
    <mat-tab-group
      animationDuration="0ms"
      [selectedIndex]="selectedTab.selected"
      (selectedIndexChange)="selectedTab.select($event)"
    >
      <mat-tab *ngIf="isApplier || !isNewCompany" label="Bewerber">
        <app-applier-sidebar
          *ngIf="drawer.opened"
          [applierId]="match?.applier?.id"
          [editMode]="false"
          (updateAndClose)="updateAndClose($event)"
          (update)="update($event)"
          (doClose)="close($event)"
          (isJobUpdated)="checkIsJobUpdated($event)"
        >
        </app-applier-sidebar>
      </mat-tab>
      <mat-tab *ngIf="!isNewCompany || !isApplier" label="Unternehmen">
        <app-companysidebar
          *ngIf="drawer.opened"
          [editMode]="false"
          (updateAndClose)="updateAndClose($event)"
          (close)="close($event)"
          (update)="update($event)"
          [companyId]="match?.company?.id"
          [isNewCompany]="'false'"
        >
        </app-companysidebar>
      </mat-tab>
      <mat-tab label="Job">
        <app-job-sidebar
          *ngIf="drawer.opened"
          [editMode]="false"
          (updateAndClose)="updateAndClose($event)"
          (update)="update($event)"
          (close)="close($event)"
          [jobId]="match?.job?.id"
          [dropdownCompanies]="dropdownCompanies"
          [companyId]="companyId"
          [isDublicate]="false"
          [isNewJob]="false"
          (isJobUpdated)="checkIsJobUpdated($event)"
        >
        </app-job-sidebar>
      </mat-tab>
      <mat-tab label="Verlauf">
        <app-match-history
          *ngIf="drawer.opened"
          [applierId]="applierId"
          (update)="update($event)"
          [match]="match"
          (updateParentNeedAction)="updateNeedAction($event)"
        >
        </app-match-history>
      </mat-tab>
    </mat-tab-group>
  </mat-drawer>
  <!-- Filter Start  -->
  <div class="row filters-row">
    <div class="col filters-col">
      <ng-multiselect-dropdown
        (onSelect)="queryBy('companies', selectedAgent)"
        (onDeSelect)="queryBy('companies', selectedAgent)"
        class="multiselect-dropdown"
        placeholder="Ku/Be-Betreuer"
        [settings]="dropdownSettings"
        [data]="dropdownAgents"
        [(ngModel)]="selectedAgent"
        [ngModelOptions]="{ standalone: true }"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col filters-col">
      <ng-multiselect-dropdown
        (onSelect)="queryBy('account_manager_ids', selectedAgentManager)"
        (onDeSelect)="queryBy('account_manager_ids', selectedAgentManager)"
        class="multiselect-dropdown"
        placeholder="Kundenbetreuer"
        [settings]="dropdownSettings"
        [data]="dropdownAgents"
        [(ngModel)]="selectedAgentManager"
        [ngModelOptions]="{ standalone: true }"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col filters-col">
      <mat-form-field style="width: 150%">
        <mat-label translate>home.search_placeholder</mat-label>
        <input
          matInput
          [(ngModel)]="searchText"
          (input)="getBySearch()"
          placeholder="{{ 'home.search_placeholder_value' | translate }}"
          #input
        />
      </mat-form-field>
    </div>
    <div class="col filters-col" style="padding-left: 10%">
      <mat-checkbox
        [(ngModel)]="needActionChecked"
        (change)="getActionNeeded()"
      >
        {{ 'home.search_need_action' | translate }}
      </mat-checkbox>
    </div>
    <div
      class="col filters-col filters-col-home"
      style="width: 100%; text-align: right"
    >
      <button
        mat-flat-button
        class="btn"
        style="margin-bottom: 15px"
        (click)="ShowFilter = !ShowFilter"
      >
        {{ 'jobs.more_filters' | translate }}
      </button>
      <button
        mat-flat-button
        class="btn"
        style="margin-bottom: 15px"
        (click)="exportDialog()"
      >
        Excel Export
      </button>
    </div>
  </div>

  <div
    class="row filters-row expand"
    [attr.colspan]="columnsToDisplay.length"
    [@detailExpand]="ShowFilter ? 'expanded' : 'collapsed'"
  >
    <div class="col filters-col">
      <ng-multiselect-dropdown
        (onSelect)="queryBy('company_ids', selectedCompanies)"
        (onDeSelect)="queryBy('company_ids', selectedCompanies)"
        class="multiselect-dropdown"
        placeholder="Unternehmen"
        [settings]="dropdownSettings"
        [data]="dropdownCompanies"
        [(ngModel)]="selectedCompanies"
        [ngModelOptions]="{ standalone: true }"
      >
      </ng-multiselect-dropdown>
    </div>

    <div class="col filters-col">
      <ng-multiselect-dropdown
        placeholder="Status"
        class="multiselect-dropdown"
        (onSelect)="queryBy('stage', selectedStatus)"
        (onDeSelect)="queryBy('stage', selectedStatus)"
        [settings]="dropdownSettings"
        [data]="constants.getList('match_stage') | async"
        [(ngModel)]="selectedStatus"
        [ngModelOptions]="{ standalone: true }"
      >
      </ng-multiselect-dropdown>
    </div>

    <div class="col filters-col">
      <ng-multiselect-dropdown
        (onSelect)="queryBy('reference_by', selectedReferenceBy)"
        (onDeSelect)="queryBy('reference_by', selectedReferenceBy)"
        class="multiselect-dropdown"
        placeholder="Herkunft"
        [settings]="dropdownSettings"
        [data]="constants.getList('reference_type') | async"
        [(ngModel)]="selectedReferenceBy"
        [ngModelOptions]="{ standalone: true }"
      >
      </ng-multiselect-dropdown>
    </div>

    <fieldset name="job filters" class="filter-fieldset">
      <legend>Job</legend>

      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          (onSelect)="queryBy('education_type', selectedEducationItems)"
          (onDeSelect)="queryBy('education_type', selectedEducationItems)"
          placeholder="{{ 'job_details.select' | translate }}  {{
            'job_details.education_type' | translate
          }}"
          [settings]="dropdownSettings"
          [data]="constants.getList('education_type') | async"
          [(ngModel)]="selectedEducationItems"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          (onSelect)="queryBy('continuing_education', selectedContiEduItems)"
          (onDeSelect)="queryBy('continuing_education', selectedContiEduItems)"
          placeholder="{{ 'job_details.select' | translate }}  {{
            'home.col_continuing_educations' | translate
          }}"
          [settings]="dropdownSettings"
          [data]="constants.getList('continuing_education_type') | async"
          [(ngModel)]="selectedContiEduItems"
        >
        </ng-multiselect-dropdown>
      </div>
    </fieldset>

    <div class="col filters-col">
      <mat-form-field>
        <mat-label translate>analytics.date</mat-label>
        <input
          type="text"
          autocomplete="off"
          ngxDaterangepickerMd
          matInput
          [showCustomRangeLabel]="true"
          [alwaysShowCalendars]="true"
          [ranges]="ranges"
          [linkedCalendars]="true"
          [showClearButton]="true"
          placeholder="Select please..."
          [autoApply]="false"
          [closeOnAutoApply]="true"
          [maxDate]="maxDate"
          [minDate]="minDate"
          [(ngModel)]="selectedDateRange"
          [locale]="locale"
          (change)="onSelect(selectedDateRange)"
        />
      </mat-form-field>
    </div>

    <fieldset name="agent filter" class="filter-fieldset">
      <legend>Bewerber</legend>
      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          (onSelect)="
            queryBy('applier_education_type', selectedApplierEducationTypes)
          "
          (onDeSelect)="
            queryBy('applier_education_type', selectedApplierEducationTypes)
          "
          placeholder="{{ 'job_details.select' | translate }}  {{
            'job_details.education_type' | translate
          }}"
          [settings]="dropdownSettings"
          [data]="constants.getList('education_type') | async"
          [(ngModel)]="selectedApplierEducationTypes"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          (onSelect)="
            queryBy(
              'applier_continuing_education',
              selectedApplierContiEduItems
            )
          "
          (onDeSelect)="
            queryBy(
              'applier_continuing_education',
              selectedApplierContiEduItems
            )
          "
          placeholder="{{ 'job_details.select' | translate }}  {{
            'home.col_continuing_educations' | translate
          }}"
          [settings]="dropdownSettings"
          [data]="constants.getList('continuing_education_type') | async"
          [(ngModel)]="selectedApplierContiEduItems"
        >
        </ng-multiselect-dropdown>
      </div>
    </fieldset>
  </div>

  <!--Start with table-->
  <mat-table
    class="mat-elevation-z8"
    mat-table
    multiTemplateDataRows
    matSortDisableClear
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortBy($event)"
    matSortStart="asc"
  >
    <!--add the company name-->
    <ng-container matColumnDef="applier_name">
      <mat-header-cell *matHeaderCellDef mat-sort-header="applier__name">
        {{ 'match.applier_name' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element?.applier?.user?.first_name }}
        {{ element?.applier?.user?.last_name }}
        <mat-icon class="company-edit" (click)="getMatch(element, 0)"
          >visibility
        </mat-icon>
      </mat-cell>
    </ng-container>

    <!-- add the job title-->

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header="forwarding_date">
        Weiterleitungsdatum</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        {{ element?.forwarding_date | date }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="updated_at">
      <mat-header-cell *matHeaderCellDef mat-sort-header="updated_at">
        Zuletzt geändert</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        {{ element?.updated_at | date }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="company_name">
      <mat-header-cell *matHeaderCellDef mat-sort-header="company__name">
        {{ 'match.company_name' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element?.company?.name }}
        <mat-icon class="company-edit" (click)="getMatch(element, 1)"
          >visibility
        </mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="location">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header="job__city,job__postcode"
      >
        {{ 'match.location' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element?.job?.health_facility_location?.postcode }}
        {{ element?.job?.health_facility_location?.city }}
        <mat-icon class="add-job" (click)="getMatch(element, 2)" r
          >visibility
        </mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="agent_name">
      <mat-header-cell *matHeaderCellDef translate>
        match.agent_name
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <tag
          *ngIf="
            element?.company?.agent_user?.first_name ||
            element?.company?.agent_user?.last_name
          "
          >{{ element?.company?.agent_user?.first_name }}
          {{ element?.company?.agent_user?.last_name }}</tag
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="caretype">
      <mat-header-cell *matHeaderCellDef translate>
        home.col_care_type
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="element?.job.care_type?.length > 0">
          <tag *ngFor="let ct of element?.job.care_type">
            {{ ct | constantLabel : 'care_type' | async }}
          </tag>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="account_manager">
      <mat-header-cell *matHeaderCellDef translate>
        match.account_manager
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <tag
          *ngIf="
            element?.applier?.agent_user?.first_name ||
            element?.applier?.agent_user?.last_name
          "
        >
          {{ element?.applier?.agent_user?.first_name }}
          {{ element?.applier?.agent_user?.last_name }}</tag
        >
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef translate>
        <span>
          {{ 'match.status' | translate }}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element?.stage | constantLabel : 'match_stage' | async }}
        <div class="tags">
          <tag *ngFor="let flag of element?.flags" [color]="'secondary'">
            {{ flag.flag | constantLabel : 'match_flag' | async }}
          </tag>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef mat-sort-header="created_at" translate>
        {{ 'home.col_created_date' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element?.created_at | date }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element" style="text-align: end">
        <mat-icon class="action-icon" (click)="getMatch(element, 3)"
          >history
        </mat-icon>

        <mat-icon
          *ngIf="element?.company_note"
          matTooltip="Notiz des Unternehmens: {{
            htmlToPlainText(element?.company_note)
          }}"
          class="action-icon"
        >
          note
        </mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let element; columns: columnsToDisplay"
      id="{{ element.id }}_match"
      [class.need_action]="element?.need_action"
    ></mat-row>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="10">
        {{ 'message.no_record' | translate }}
      </td>
    </tr>
  </mat-table>
  <div class="mat-elevation-z8 paginator-row" *ngIf="dataSource.page$ | async">
    <div class="progress-bar">
      <mat-progress-bar mode="buffer" *ngIf="dataSource.loading$ | async">
      </mat-progress-bar>
    </div>

    <mat-paginator
      *ngIf="dataSource.page$ | async as page"
      [length]="page.totalElements"
      [pageSize]="10"
      [showFirstLastButtons]="true"
      [pageIndex]="page.number"
      [hidePageSize]="true"
      (page)="pageEvent = handlePage($event)"
      (page)="dataSource.fetch($event.pageIndex)"
    >
      {{ dataSource.page }} dataSource.page$
    </mat-paginator>
  </div>
  <div
    *ngIf="(dataSource.loading$ | async) !== false && !curentPage"
    class="skeleton-loader"
  >
    <ngx-skeleton-loader
      count="10"
      appearance="line"
      [theme]="{ 'height.px': 35 }"
    ></ngx-skeleton-loader>
  </div>
  <app-row-selecter
    [uniqueId]="columnKey"
    [allRows]="columnsAll"
    [selectedRows]="columnsToDisplay"
  >
  </app-row-selecter>
</mat-drawer-container>
