import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { take } from 'rxjs/operators';

import { JobService } from 'src/app/services/job.service';

@Component({
  selector: 'app-job-upload-content',
  templateUrl: './job-upload-content.component.html',
  styleUrls: ['./job-upload-content.component.scss'],
})
export class JobUploadContentComponent implements OnInit {
  dropdownSettings: IDropdownSettings;
  dropdownCompanies: any[];
  selectedCompany: { item_id: number; item_text: string }[];
  job_csv: File;
  isLoading: boolean = false;

  constructor(private jobService: JobService, private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.getCompanyUser();
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      searchPlaceholderText: 'Suche',
      selectAllText: 'Alle ausw�hlen',
      unSelectAllText: 'kein Filter',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
    };
  }

  onFileSelected(event) {
    this.job_csv = event?.target?.files?.[0];
  }

  uploadCSV() {
    const formData = new FormData();
    formData.append('jobs_csv', this.job_csv);
    formData.append('company_id', '' + this.selectedCompany?.[0]?.item_id);
    this.isLoading = true;
    this.jobService
      .uploadCSV(formData)
      .pipe(take(1))
      .subscribe({
        next: response => {
          this._snackBar.open(
            "The CSV file was uploaded and will now be imported in the background. You'll receive an email after it's finished.",
            'Success',
            { duration: 5000 }
          );
        },
        error: err =>
          this._snackBar.open(
            err ?? 'The CSV file could not be imported due to an error.',
            'Failure',
            { duration: 5000 }
          ),
        complete() {},
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  getCompanyUser() {
    this.jobService.getCompanies({ minimal: true }).subscribe((res: any) => {
      this.dropdownCompanies = res.map(company => ({
        item_id: company.id,
        item_text: company.name,
      }));
    });
  }
}
