<div class="header-btn">
  <h1>
    <span class="comname">{{ companyDetails.name }}</span>
    <span *ngIf="companyDetails">
      <button
        mat-raised-button
        class="btn-add-user"
        color="Default"
        (click)="addCompanyDialog()"
      >
        {{ 'create_job.add_user' | translate }}
      </button>
    </span>
  </h1>
</div>
<div class="content">
  <div class="company-edit">
    <!------------------------------------------------ Company View Mode ------------------------------------------->

    <table class="table">
      <tr>
        <mat-card *ngIf="companyDetails">
          <mat-list>
            <mat-list-item>
              <span class="company-title"
                >{{ 'company.external_name' | translate }}:</span
              >
              <span class="company-value">{{
                companyDetails.external_name
              }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item
              ><span class="company-title"
                >{{ 'create_job.website' | translate }}:
              </span>
              <span class="company-value">{{ companyDetails.website }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item
              ><span class="company-title">
                {{ 'create_job.agent_user' | translate }}:
              </span>
              <span class="company-value"
                >{{ companyDetails.agent_user?.first_name }}&nbsp;{{
                  companyDetails.agent_user?.last_name
                }}</span
              >
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item
              ><span class="company-title">
                {{ 'create_job.phone_number' | translate }}:</span
              >
              <span class="company-value">{{
                companyDetails.phone_number
              }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>

            <mat-divider></mat-divider>
            <mat-list-item
              ><span class="company-title"
                >{{ 'company.company_size' | translate }}:</span
              >
              <span class="company-value">{{
                companyDetails.company_size
              }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item
              ><span class="company-title"
                >{{ 'create_job.sales' | translate }}:</span
              >
              <span class="company-value">
                <mat-slide-toggle
                  color="primary"
                  class="mt-2 w-100"
                  [disabled]="true"
                  value="isChecked"
                  [(ngModel)]="isChecked"
                >
                </mat-slide-toggle>
              </span>
            </mat-list-item>
            <mat-divider></mat-divider>

            <mat-list-item
              ><span class="company-title">Reminders:</span>
              <span class="company-value">
                <mat-slide-toggle
                  aria-label="send reminders to company agent users"
                  color="primary"
                  class="mt-2 w-100"
                  [(ngModel)]="companyDetails.send_reminder"
                  (change)="doUpdateCompany($event)"
                >
                </mat-slide-toggle>
              </span>
            </mat-list-item>
          </mat-list>
        </mat-card>

        <ng-container *ngIf="!companyDetails">
          <ngx-skeleton-loader
            count="6"
            appearance="line"
            [theme]="{ 'height.px': 30 }"
          ></ngx-skeleton-loader>
        </ng-container>
      </tr>

      <!----------------------------------------------- Company User Table --------------------------------------------->

      <tr *ngIf="companyDetails">
        <mat-card>
          <mat-table
            class="mat-elevation-z8 users"
            mat-table
            multiTemplateDataRows
            [dataSource]="dataSource"
          >
            <!--add the company name-->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef translate>
                create_job.col_name
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.first_name }}
                {{ element.last_name }}
              </mat-cell>
            </ng-container>

            <!-- show email and phone number -->
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef translate>
                create_job.email
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element?.email }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="phone">
              <mat-header-cell *matHeaderCellDef translate>
                create_job.phone_number
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element?.phone }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="gender">
              <mat-header-cell *matHeaderCellDef translate>
                create_job.gender
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span>
                  {{ getGender(element) }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="contactuser">
              <mat-header-cell *matHeaderCellDef translate>
                create_job.col_contact_user
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-icon>{{
                  element?.contactuser ? 'done' : 'close'
                }}</mat-icon>
                <mat-icon
                  matTooltip="Standard-Kontaktbenutzer"
                  *ngIf="element?.default_contact_user"
                >
                  contact_emergency
                </mat-icon>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef translate>
                action
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button
                  mat-icon-button
                  class="action-icon ml-2"
                  [matMenuTriggerFor]="menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
                  <mat-slide-toggle
                    class="company-slide-toggle action-icon"
                    style="margin: 5px 12px 5px 12px"
                    [checked]="element.contactuser"
                    (change)="updateContactUser(element?.id, $event)"
                    color="primary"
                  >
                    <span>
                      {{
                        element?.contactuser ? 'inaktiv' : 'Aktivieren'
                      }}</span
                    >
                  </mat-slide-toggle>
                  <button mat-menu-item (click)="editCompanyUser(element)">
                    bearbeiten
                  </button>

                  <button
                    mat-menu-item
                    (click)="impersonateUserOnCompanyApp(element.id)"
                  >
                    sich als dieser Benutzer ausgeben
                  </button>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="columnsToDisplay"
            ></mat-header-row>
            <mat-row
              *matRowDef="let element; columns: columnsToDisplay"
            ></mat-row>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="10">
                {{ 'message.no_record' | translate }}
              </td>
            </tr>
          </mat-table>
        </mat-card>
      </tr>
    </table>
  </div>
  <div class="company-details">
    <div *ngIf="!companyDetails">
      <ng-container>
        <ngx-skeleton-loader
          count="6"
          appearance="line"
          [theme]="{ 'height.px': 30 }"
        ></ngx-skeleton-loader>
      </ng-container>
    </div>
  </div>
</div>
