import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { JobService } from 'src/app/services/job.service';
import { Location } from '@angular/common';
import { PaginationDataSource } from 'ngx-pagination-data-source';
import { Sortable } from '../match/match.component';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-company-home',
  templateUrl: './company-home.component.html',
  styleUrls: ['./company-home.component.scss'],
})
export class CompanyHomeComponent implements OnInit {
  companies: Array<any> = [];
  dropdownAgents = [];
  selectedAgent = [];
  selectedCompanyId: any;
  screenHeight: number;
  companyForm: FormGroup;
  companyDetails: string = '';
  search = new FormControl();
  comName = new FormControl();
  companySearch: string = '';
  companySearchUpdate = new Subject<string>();
  searchSubcription: Subscription;
  mymodel: string = '';
  selectedOptions: number[];
  companyId: number;
  page: number = 1;
  url: string;
  nextPage: string;
  noOfItemsToShowInitially: number = 5;
  itemsToLoad: number = 5;
  isFullListDisplayed: boolean = false;
  dropdownSettings: IDropdownSettings;
  isFetchingCompanies: boolean = false;

  dataSource = new PaginationDataSource(
    (request, query) => this.jobService.match(request, query),
    { property: 'forwarding_date' as keyof Sortable, order: 'desc' },
    {
      companies: '',
      status: '',
      search: '',
      need_action: '',
      company_ids: '',
      start_date: '',
      end_date: '',
    },
    2
  );
  constructor(
    private jobService: JobService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.screenHeight = window.innerHeight - 10;

    this.searchSubcription = this.companySearchUpdate
      .pipe(debounceTime(400))
      .subscribe(value => {
        this.getCompanyUser(true);
      });
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
    };
    this.companyForm = this._formBuilder.group({
      search: [],
    });
    this.getCompanyUser();
    this.getAgentList();
  }

  ngOnDestroy() {
    this.searchSubcription.unsubscribe();
  }

  getCompanyUser(reset = false) {
    if (this.nextPage === null && !reset) return;
    if (reset) this.page = 1;

    const cName = this.companyForm.value.search || '';
    const agent_ids = this.selectedAgent.map(agent => agent.item_id).join(',');

    if (cName.length == 0 || cName.length > 2) {
      this.isFetchingCompanies = true;
      this.jobService
        .getCompaniesByName(this.page, {
          companyName: encodeURIComponent(cName),
          agentIds: agent_ids.length ? agent_ids : '',
          minimal: true,
        })
        .subscribe({
          next: (response: any) => {
            this.nextPage = response.next;

            if (this.page > 1) {
              this.companies = this.companies.concat(response.results);
            } else {
              this.companies = response.results;
            }

            if (this.route.params['_value'].id) {
              this.companyId = this.route.params['_value'].id;
            } else {
              this.companyId = this.companies?.[0]?.id ?? '';
              this._router.navigate(['companies', this.companyId]);
            }

            if (reset && !cName) this.companyDetails = this.companies[0];

            this.selectedOptions = this.companyId
              ? [this.companyId]
              : [this.companies[0]?.id];
          },
          complete: () => {
            this.isFetchingCompanies = false;
          },
        });
    }
  }

  getAgentList() {
    this.jobService
      .getAgent(true)
      .subscribe(
        (res: { id: string; first_name: string; last_name: string }[]) => {
          this.dropdownAgents = res.map(agent => {
            return {
              item_id: agent.id,
              item_text: agent.first_name + ' ' + agent.last_name,
            };
          });
        }
      );
  }

  selectedCompany(cName, company_id) {
    this._router.navigate(['companies', company_id]);
    this.companyDetails = cName;
  }

  refreshCompany(companyArray) {
    var target = this.companies.findIndex(temp => temp?.id == companyArray?.id);
    this.companies[target] = companyArray;
  }

  onScroll() {
    if (this.noOfItemsToShowInitially <= this.companies.length) {
      this.page = this.page + 1;
      this.getCompanyUser();
    } else {
      this.isFullListDisplayed = true;
    }
  }
}
