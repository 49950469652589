export const actors = {
  AGENT: { id: 'AGENT', label: 'Betreuer' },
  APPLIER: { id: 'APPLIER', label: 'Bewerber' },
  COMPANY: { id: 'COMPANY', label: 'Unternehmen' },
  SYSTEM: { id: 'SYSTEM', label: 'System' },
};

export function getActor(id: string): { id: string; label: string } {
  return actors?.[id] ?? {};
}
