import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HistoryMatchService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {}

  get(url, params = {}) {
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(catchError(this.handleError));
  }

  post(url, data, httpOptions = {}) {
    return this.http
      .post(
        `${environment.BASE_URL}${environment.PATH}${url}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  put(url, data, httpOptions = {}) {
    return this.http
      .put(
        `${environment.BASE_URL}${environment.PATH}${url}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  delete(url, httpOptions = {}) {
    return this.http
      .delete(`${environment.BASE_URL}${environment.PATH}${url}`, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getPdf(url, params = {}) {
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(catchError(this.handleError));
  }

  public handleError(errorRes: HttpErrorResponse) {
    if (Object.keys(errorRes?.error || {}).length) {
      if (
        Object.keys(errorRes?.error)[0]?.length > 1 &&
        errorRes?.statusText !== 'Unknown Error'
      ) {
        return throwError(
          Object.keys(errorRes.error)[0] +
            ' ' +
            Object.values(errorRes.error)[0]
        );
      }
    }
    let message = errorRes?.statusText
      ? errorRes?.statusText
      : 'etwas ist schief gelaufen!';
    return throwError(message);
  }

  getMatchById(matchId) {
    if (!matchId) return;
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`match/matching/${matchId}/`, httpOptions);
  }

  getMatchHistoryHistory(matchId) {
    if (!matchId) return;
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`match/match-history/${matchId}/`, httpOptions);
  }

  getMatchFlags(matchId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`match/match-flag/${matchId}/`, httpOptions);
  }

  createMatchFlags(data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.post(`match/match-flag/`, data, httpOptions);
  }

  deletedMatchFlags(matchId, data) {
    const httpOptions = this.authService.getHTTPOption();
    httpOptions['body'] = data;
    return this.http.delete(
      `${environment.BASE_URL}${environment.PATH}match/match-flag/${matchId}/`,
      httpOptions
    );
  }

  addHistoryMatchData(data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.post(`match/history-match-item/`, data, httpOptions);
  }

  getHistoryMatchStatus() {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`match/status-match-item/`, httpOptions);
  }

  getExcelExportData(fields, options = {}) {
    let token = this.authService.getAuthToken();
    let httpOptions = new HttpHeaders({
      Authorization: token,
    });
    const params = new URLSearchParams(options).toString();
    return this.getPdf(`match/export/excel/?fields=${fields}&${params}`, {
      responseType: 'blob' as 'json',
      headers: httpOptions,
    });
  }

  getHistoryMatchLabel() {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`match/label-match-item/`, httpOptions);
  }

  updateHistoryMatchlabel(id, data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.put(`match/matching/${id}/`, data, httpOptions);
  }
}
