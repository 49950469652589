<h1 mat-dialog-title>Standort hinzufügen</h1>
<form
  mat-dialog-content
  class="body"
  [formGroup]="address"
  (submit)="saveFacility()"
>
  <div class="street">
    <label for="street">Street</label>
    <input type="text" id="street" formControlName="street" autofocus />
  </div>
  <div class="house_number">
    <label for="house number">House number </label>
    <input type="text" id="house number" formControlName="house_number" />
  </div>
  <div class="city">
    <label for="city">City</label>
    <input type="text" id="city" formControlName="city" />
    <div
      [class.visible]="city.invalid && (city.touched || city.dirty)"
      class="error"
    >
      city is required
    </div>
  </div>
  <div class="postcode">
    <label for="postcode">Postcode</label>
    <input type="text" id="postcode" formControlName="postcode" />
    <div
      [class.visible]="postcode.invalid && (postcode.touched || postcode.dirty)"
      class="error"
    >
      <div *ngIf="postcode.hasError('required')">Postcode is required.</div>
      <div *ngIf="postcode.hasError('pattern')">Postcode must be a number.</div>
    </div>
  </div>
  <div class="country">
    <label for="country">Country</label>
    <select name="countries" id="country" formControlName="country">
      <option value="DE" selected>germany</option>
    </select>
  </div>
  <div mat-dialog-actions class="actions">
    <button mat-button mat-dialog-close mat-flat-button>Close</button>
    <button
      mat-button
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="address.invalid"
    >
      Save
    </button>
  </div>
</form>
