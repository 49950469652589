import { Component, OnInit, ViewChild, Optional } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalService } from '@azure/msal-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth, User, signInWithPopup, OAuthProvider } from '@angular/fire/auth';
import { AuthenticationResult } from '@azure/msal-browser';
import { AuthService } from 'src/app/services/auth.service';

interface MicrosftUser {
  '@odata.context': string;
  businessPhones: any[];
  displayName: string;
  givenName: string;
  jobTitle: null;
  mail: string;
  mobilePhone: null;
  officeLocation: null;
  preferredLanguage: null;
  surname: string;
  userPrincipalName: string;
  id: string;
  error?: Error;
}

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss'],
})
export class AdminLoginComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  returnUrl: string;
  constructor(
    private msalService: MsalService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    @Optional() private auth: Auth
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['returnUrl']) {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
    if (localStorage.getItem('agentData') !== null) {
      this.router.navigate(['applicants']);
    } else if (localStorage.getItem('companyData') !== null) {
      this.router.navigate(['dashboard']);
    }
  }

  async login() {
    try {
      const provider = new OAuthProvider('microsoft.com');
      provider.setCustomParameters({
        tenant: 'a94e7ae5-d13f-46c7-812b-f72905ea09dd',
      });
      const result = await signInWithPopup(this.auth, provider);
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;

      const headers = new Headers();
      headers.append('Authorization', `Bearer ${accessToken}`);
      const response = await fetch('https://graph.microsoft.com/v1.0/me', {
        method: 'GET',
        headers: headers,
        redirect: 'follow',
      });
      const microsoftUser: MicrosftUser = await response.json();
      if (microsoftUser?.error) throw microsoftUser;
      const user: User = result.user;
      localStorage.setItem('msalAccount', JSON.stringify(microsoftUser));
      const postData = {
        first_name: microsoftUser.displayName,
        last_name: microsoftUser.surname,
        email: microsoftUser.mail,
        token: await user.getIdToken(),
        social_account: 1,
        role: 2,
      };

      this.authService.doSignIn(postData).subscribe({
        next: () => {
          if (this.returnUrl && this.returnUrl !== '/') {
            window.location.href = this.returnUrl;
          } else {
            window.location.href = `/applicants`;
          }
        },
        error: (e) => {
          this._snackBar.open(
            e?.error?.message ?? 'something went wrong',
            'OK',
            {
              duration: 5000,
            }
          );
        },
        complete: () => console.info('complete'),
      });
    } catch (e) {
      this._snackBar.open(e?.error?.message ?? 'something went wrong', 'OK', {
        duration: 5000,
      });
    }
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }

  logins() {
    // this.msalService.loginRedirect();
    this.msalService
      .loginPopup()
      .subscribe((response: AuthenticationResult) => {
        localStorage.setItem('msalAccount', JSON.stringify(response.account));
        this.router.navigate(['applicants']);
        let nm = response?.account?.name.split(' ');

        let postData = {
          first_name: nm[0],
          last_name: nm[1],

          email: response.account.username,
          token: response.accessToken,
          social_account: 1,
          role: 2,
        };

        this.authService.doSignIn(postData).subscribe(
          (res) => {
            if (this.returnUrl && this.returnUrl !== '/') {
              window.location.href = this.returnUrl;
            } else {
              window.location.href = `/applicants`;
            }
          },
          (errorMessage) => {}
        );
      });
  }
}
