import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HistoryMatchService } from 'src/app/services/history-match.service';

@Component({
  selector: 'app-add-label',
  templateUrl: './add-label.component.html',
  styleUrls: ['./add-label.component.scss'],
})
export class AddLabelComponent implements OnInit {
  labelData = [];
  selectLable: any;
  selectMatchLable: [];
  selectedLable: [];
  select: [];
  checkValueChange: boolean = false;
  checkLabelChange: boolean = true;
  dropdownSettings: IDropdownSettings;
  singleSelectionDropdownSettings: IDropdownSettings;
  @Input() matchId: number;
  @Input() labelIds: number[];

  ngOnChanges(changes: SimpleChanges) {
    this.setSelectedLabelItems();
  }

  constructor(private historyMatchService: HistoryMatchService) {}

  ngOnInit(): void {
    const commonDropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
      searchPlaceholderText: 'Suche',
    };
    this.dropdownSettings = {
      ...commonDropdownSettings,
      singleSelection: false,
      enableCheckAll: false,
      itemsShowLimit: 20,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
    };

    this.singleSelectionDropdownSettings = {
      ...commonDropdownSettings,
      singleSelection: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      allowSearchFilter: false,
    };

    this.getHistoryMatchLabel();
  }

  getHistoryMatchLabel() {
    this.historyMatchService.getHistoryMatchLabel().subscribe((res: any) => {
      let labels = [];
      res.results.forEach((label) =>
        labels.push({
          item_id: label.id,
          item_text: label.label,
        }),
      );
      this.labelData = labels;
      this.setSelectedLabelItems();
    });
  }

  setSelectedLabelItems() {
    this.selectLable = this.labelData.filter((label) =>
      this.labelIds.includes(label.item_id),
    );
  }

  queryBy(selectLable) {
    this.selectedLable = selectLable ? selectLable.map((x1) => x1.item_id) : '';
    this.checkLabelChange = false;
    if (this.selectLable) {
      this.checkLabelChange = !(selectLable === this.select);
    }
    this.select = selectLable;
  }

  updatelabel() {
    this.checkValueChange = true;
    const data = {
      label: this.selectedLable,
    };
    this.historyMatchService
      .updateHistoryMatchlabel(this.matchId, data)
      .subscribe((res: any) => {
        if (res) {
          this.checkValueChange = false;
          this.checkLabelChange = false;
        } else {
          this.checkValueChange = true;
        }
      });
  }
}
