import { Clipboard } from '@angular/cdk/clipboard';
import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

import {
  AbbreviationModel,
  Constants,
} from 'src/app/models/abbreviation.model';
import { actors, getActor } from 'src/app/models/abbreviations/applierStatus';
import { getCommuncationLabel } from 'src/app/models/abbreviations/communicationsTypes';
import { History } from 'src/app/models/history.model';
import { ApplicantService } from 'src/app/services/applicant.service';
import { ConstItem } from 'src/app/services/constants.service';
import { HistoryService } from 'src/app/services/history.service';
import { SendWhatsappDialogComponent } from '../send-whatsapp-dialog/send-whatsapp-dialog.component';

export interface Channel {
  id: number;
  created_at: Date;
  updated_at: Date;
  channel_name: string;
  icon: string;
  due_days?: number;
  due_time?: string;
}

@Component({
  selector: 'app-history-sidebar',
  templateUrl: './history-sidebar.component.html',
  styleUrls: ['./history-sidebar.component.scss'],
})
export class HistorySidebarComponent implements OnInit {
  @Input() applierId: any;
  @Input() applier: any;
  @Output() doClose = new EventEmitter<any>();
  @Output() refreshApplier = new EventEmitter<any>();
  getActorLabel = getActor;
  communcationLabel = getCommuncationLabel;
  selectedApplierStatus: string;
  status: any;
  icn: string;
  chnlnm: string;
  showSpinner = false;
  historyForm: FormGroup;
  channels: any = [];
  statis: any;
  now: any = new Date();
  historyData: any;
  histories: any;
  returnValue: string;
  isDueDate = true;
  siblingApplierId: number;
  dropdownSettings: any = {};
  lookupFields: string[] = [
    'care_type',
    'shift_hour',
    'working_type',
    'education',
    'position',
    'area_of_experience',
    'work_experience',
    'reference_by',
    'driving_license',
    'continuing_education',
    'vaccination_status',
  ];

  selectedFlag = [];

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private historyService: HistoryService,
    private translate: TranslateService,
    private applicantService: ApplicantService,
    private clipboard: Clipboard,
    public dialog: MatDialog,
    public constants: Constants
  ) {}

  ngOnInit(): void {
    // make a API Call for that
    this.initForm();
    const baseDropDownSettigs = {
      singleSelection: true,
      idField: 'id',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 6,
      allowSearchFilter: false,
      enableCheckAll: false,
    };
    this.dropdownSettings = {
      ...baseDropDownSettigs,
      singleSelection: false,
    };
  }

  initForm() {
    this.historyForm = this._formBuilder.group({
      content: [''],
      channel_id: ['', Validators.required],
      status_id: ['', Validators.required],
      due_date: [''],
    });
    this.selectedApplierStatus = this.applier?.status;
    this.getApplierFlags();
    this.getChannel();
    this.getStatus();
    this.getApplierHistory();
  }

  close() {
    this.doClose.emit();
  }

  recentApplicant = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return Number(a['key']) > Number(b['key']) ? 0 : 1;
  };

  clearDate(event) {
    event.stopPropagation();
    this.historyForm.patchValue({ costum_Date: null });
  }

  updateDate(channel: Channel) {
    if (!channel.due_days)
      return this.historyForm.patchValue({ due_date: null });
    let [hour = 0, minutes = 0, seconds = 0] =
      channel.due_time?.split(':') ?? [];

    const due_date = moment()
      .add(channel.due_days ?? 0, 'days')
      .set({ hour: +hour, minute: +minutes, second: +seconds })
      .toDate();

    this.historyForm.patchValue({ due_date });
  }

  updateApplierStatus() {
    const status = {
      status: this.selectedApplierStatus,
    };
    this.applicantService
      .patchApplier(this.applierId, status)
      .pipe(take(1))
      .subscribe(
        (response: any) => {
          this._snackBar.open('Applier status updated', 'OK', {
            duration: 5000,
          });
          this.getApplierHistory();
          this.refreshApplier.emit();
        },
        errorRes => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
  }

  submitHistory() {
    if (this.historyForm.valid == true) {
      const postData = new History(
        this.historyForm.value.content,
        this.applierId,
        this.historyForm.value.status_id,
        this.historyForm.value.channel_id,
        this.historyForm.value.due_date
      );
      this.showSpinner = true;
      this.historyService.addHistoryData(postData).subscribe(
        (response: any) => {
          this.getApplierHistory();
          this.refreshApplier.emit();
          this.historyForm.reset();
        },
        errorRes => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
    }
  }

  getApplierFlags() {
    this.applicantService.getApplierFlags(this.applierId).subscribe(
      (flags: any) => {
        this.selectedFlag = flags.map(({ flag }) => {
          return this.constants.getApplierFlags(flag);
        });
      },
      errorRes => {
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      }
    );
  }

  onFlagSelect($event) {
    this.applicantService
      .createApplierFlags({
        flag: $event.id,
        applier: this.applierId,
      })
      .subscribe(response => {
        this.getApplierHistory();
        this._snackBar.open('flag created successfully', 'OK', {
          duration: 3000,
        });
      });
  }

  onFlagDeSelect($event) {
    this.applicantService
      .deletedApplierFlags(this.applierId, { flag: $event.id })
      .subscribe(response => {
        this.getApplierHistory();
        this._snackBar.open('flag removed successfully', 'OK', {
          duration: 3000,
        });
      });
  }

  getApplierHistory() {
    this.historyService.getApplierHistory(this.applierId).subscribe(
      (data: Array<any>) => {
        const histories = (data ?? []).reduce((accumulator, currentValue) => {
          const key = currentValue?.applier?.id;
          if (!accumulator[key]) accumulator[key] = [];
          accumulator[key].push(currentValue);
          return accumulator;
        }, {});

        this.histories = histories;
      },
      errorRes => {
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      }
    );
  }

  positionLabel(position: number[]): string {
    let positions = [];
    this.constants
      .getList('position')
      .pipe(take(1))
      .subscribe(res => {
        positions = res;
      });
    return (position || [])
      .flatMap(val => {
        return positions.find(position_ => position_.item_id === val).label;
      })
      .join(',');
  }

  setDifference(setA, setB) {
    const _difference = new Set(setA);
    for (const elem of setB) {
      _difference.delete(elem);
    }
    return _difference;
  }

  applierFlagLabels(flags: string[]): string {
    return (flags || [])
      .flatMap(val => {
        return this.constants.getApplierFlags(val)?.label ?? '';
      })
      .join(' , ');
  }

  getStatusText(status) {
    let stages = [];
    const applierStatus = this.constants
      .getList('applier_status')
      .pipe(take(1));
    const matchStage = this.constants.getList('match_stage').pipe(take(1));
    forkJoin([applierStatus, matchStage]).subscribe(val => {
      const applier_status = val?.[0] ?? [];
      const match_stage = val?.[1] ?? [];
      stages = applier_status.concat(match_stage);
    });
    return stages.find((s: ConstItem) => s?.id === status)?.label ?? '';
  }

  getFieldLabel(changes: {
    field: string;
    old: any[] | number;
    new: any[] | number;
  }) {
    const mappings = {
      user_first_name: 'Vorname',
      user_last_name: 'Nachname',
      user_birth_date: 'Geburtsdatum',
      user_email: 'E-mail',
      user_phone_number: 'Tel.',
      user_whatsapp_number: 'WhatsApp',
      education: 'Ausbildung',
      care_type: 'Pflegeart',
      shift_hour: 'Schichten',
      working_type: 'Arbeitsumfang',
      postcode: 'Postleitzahl',
      last_2_employers: 'Letzte Arbeitgeber',
      driving_license: 'Führerschein',
      vaccination_status: 'Impfstatus',
      vaccination_date: 'Datum der Impfung',
      language_skill: 'Spachkenntnisse',
      work_experience: 'Berufserfahrung',
      area_of_experience: 'Erfahrung in',
      shift_other: 'sonstige Schicht',
      reference_by: 'Referenz von',
      status: 'Status',
      agent_user: 'Bewerberbetreuer',
      continuing_education: 'Weiterbildungen',
      continuing_education_other: 'sonst. Weiterbildungen',
      city: 'Stadt',
      position: 'Position',
      position_other: 'Sonstige Position',
      education_other: 'sonst. Ausbildung',
      note: 'Anmerkung',
      others: 'Sonstige',
      start_work_date: 'Eintrittsdatum',
    };
    return mappings[changes.field] ?? null;
  }

  getLookUpField(changes: {
    field: string;
    old: any[] | number;
    new: any[] | number;
  }) {
    const mappings = {
      care_type: 'care_type',
      vaccination_status: 'vaccination_status_type',
      shift_hour: 'shift_hour_type',
      working_type: 'shift_time_type',
      education: 'education_type',
      position: 'position',
      area_of_experience: 'area_of_experience',
      work_experience: 'work_experience',
      reference_by: 'reference_type',
      driving_license: 'driver_license_type',
      continuing_education: 'continuing_education_type',
    };
    return mappings[changes.field] ?? changes.field;
  }

  statusChangeLabel(log) {
    // new applier
    if (!log?.old_status?.length && log?.status && log?.applier)
      return 'Ein neuer Bewerber ist eingegangen';

    // matches found
    if (!log?.old_status?.length && log?.status && log?.match)
      return 'Ein neues Match wurde gefunden';

    if (log?.actor === actors.SYSTEM.id)
      return `Das System hat den Status zu "${this.getStatusText(
        log?.new_status || log?.status
      )}" geändert`;

    return `${log?.created_by?.first_name || log?.user?.first_name} ${
      log?.created_by?.last_name || log?.user?.last_name
    }  (${
      getActor(log?.actor)?.label
    })  hat den Status zu "${this.getStatusText(
      log?.new_status || log?.status
    )}" geändert`;
  }

  flagLabel(log: any) {
    const newFlags = log?.details?.new_flags ?? [];
    const oldFlags = log?.details?.old_flags ?? [];

    if (newFlags.length > oldFlags.length) {
      const flags = this.applierFlagLabels([
        ...this.setDifference(newFlags, oldFlags),
      ] as string[]);

      return ` Das  Flag "${flags}" wurde gesetzt `;
    } else {
      const flags = this.applierFlagLabels([
        ...this.setDifference(oldFlags, newFlags),
      ] as string[]);

      return `Das Flag "${flags}" wurde entfernt`;
    }
  }

  getStatus() {
    if (typeof this?.applierId !== 'undefined') {
      this.showSpinner = true;
      this.historyService.getHistoryStatus().subscribe(
        (response: any) => {
          this.status = response?.results;
        },
        errorRes => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
    }
  }

  getChannel() {
    if (typeof this?.applierId !== 'undefined') {
      this.showSpinner = true;
      this.historyService.getHistoryChannel().subscribe(
        (response: any) => {
          this.channels = response?.results;
        },
        errorRes => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
    }
  }

  changeIconNname(event: MatOptionSelectionChange, icon: string, name: string) {
    if (!event.isUserInput) return;
    this.icn = icon;
    this.chnlnm = name;
    const historyStatis = new AbbreviationModel().getList('status');
    this.statis = historyStatis.map(statis => ({
      id: Number(statis.id),
      name: statis.fields.short_name,
    }));
  }

  compare(str) {
    if (this.now >= Date.parse(str)) return true;
    return false;
  }
  delete(id) {
    // delete the posting
    // refresh match history
    this.showSpinner = true;
    this.historyService.deleteApplierHistory(id).subscribe(
      (response: any) => {
        this.getApplierHistory();
        this._snackBar.open(
          this.translate.instant('history.delete_record'),
          'OK',
          { duration: 5000 }
        );
        this.showSpinner = false;
      },
      errorRes => {
        this.showSpinner = false;
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      }
    );
  }

  sendMatches(
    channel_id: number,
    applier_id: number,
    applier_phone: string,
    status_id: number
  ) {
    let postData = {
      match_ids: [],
      status: null,
      note: '',
      note_bool: false,
      applier_id: applier_id,
      channel: channel_id === 7 ? 'whatsapp' : channel_id === 8 ? 'mail' : '',
      status_id: status_id,
      model_name: 'Status',
    };

    this.applicantService.sendMatches(applier_id, postData).subscribe(
      res => {
        this.returnValue = '';
        if (channel_id === 7 && res['output']) {
          this.returnValue = res['output'];
          this.addCompanyDialog(channel_id, applier_phone);
          this.clipboard.copy(this.returnValue);
        }
        const snakMessage =
          channel_id === 7 && res['output']
            ? this.translate.instant('message.copied_to_clipboard')
            : channel_id === 8
            ? this.translate.instant('message.email_sent')
            : 'enthalten nicht verfügbar';
        this.getApplierHistory();
        this._snackBar.open(snakMessage, '', {
          duration: 5000,
        });
      },
      error => {
        if (error !== null && error !== 'undefined') {
          this._snackBar.open(error.replace('message', ''), '', {
            duration: 5000,
          });
        } else {
          this._snackBar.open(
            this.translate.instant('message.something_wrong'),
            '',
            { duration: 5000 }
          );
        }
        // this.buttonDisable = false;
      }
    );
  }

  getDate(matches) {
    const applier = matches[0]?.applier;
    if (!applier) return;
    return applier?.created_at;
  }

  addApplicantDrawer(matches) {
    const applierId: number =
      matches[0]?.applier?.id || matches[0]?.match.applier;
    if (!applierId) return;
    this.siblingApplierId = applierId;
  }

  addCompanyDialog(channel_id: number, applier_phone: string) {
    const dialogRef = this.dialog.open(SendWhatsappDialogComponent, {
      data: {
        returnValue: this.returnValue,
        applier_phone: applier_phone,
        checkStatus: channel_id,
      },
      panelClass: 'myClass',
    });
    dialogRef.afterClosed().subscribe(result => {});
  }
}
