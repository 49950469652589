import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { JobService } from 'src/app/services/job.service';
import {
  CompanySidebarComponent,
  DialogData,
} from '../company-sidebar/companysidebar.component';

@Component({
  selector: 'app-add-company-user',
  templateUrl: './add-company-user.component.html',
  styleUrls: ['./add-company-user.component.scss'],
})
export class AddCompanyUserComponent implements OnInit {
  isNewUser = true;
  isSpinner: boolean;
  editMode: boolean;
  addCompanyForm: FormGroup;
  usertype: any;
  dropdownUserList: any;
  companyUsers: [];
  userForm: FormGroup;
  user_id: any;
  user: {
    id: number;
    email: string;
    gender: number;
    phone: string;
    first_name: string;
    last_name: string;
    name: string;
  };
  dropdownUserSettings: IDropdownSettings;
  selectedUser: any;
  constructor(
    private jobService: JobService,
    private dialogRef: MatDialogRef<CompanySidebarComponent>,
    private _snackBar: MatSnackBar,

    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.addCompanyForm = data.companyForm;
    this.dropdownUserSettings = data.dropdownUserSettings;
    this.editMode = data.editMode;
    this.isSpinner = data.isSpinner;
    this.selectedUser = data.selectedUser;
    this.user = data.user;
  }

  ngOnInit() {
    this.initForm();
    this.getUserList();
  }

  initForm() {
    this.userForm = new FormGroup({
      first_name: new FormControl(this.user?.first_name ?? ''),
      last_name: new FormControl(
        this.user?.last_name ?? '',
        Validators.required
      ),
      email: new FormControl(this.user?.email ?? '', Validators.required),
      phone_number: new FormControl(this.user?.phone ?? ''),
      gender: new FormControl(this.user?.gender ?? '', Validators.required),
      about: new FormControl(''),
      sales: new FormControl(false),
      usertype: new FormControl('new'),
      companyName: new FormControl(''),
    });

    if (this.addCompanyForm?.controls?.['companyName']?.value.length) {
      this.userForm.patchValue({
        companyName: this.addCompanyForm.controls['companyName'].value,
      });
      this.userForm.patchValue({ usertype: 'existing' });
      this.isNewUser = false;
    }

    this.userForm.get('usertype').valueChanges.subscribe((val) => {
      this.getDefaultUser();
    });
    this.addCompanyForm?.patchValue({
      usertype: this.addCompanyForm.controls['usertype'].value,
    });
    this.userForm.get('usertype').value == 'existing'
      ? this.addCompanyForm?.controls['companyName'].setValidators([
          Validators.required,
        ])
      : this.addCompanyForm?.controls['companyName'].clearValidators();
  }

  getUserList() {
    this.dropdownUserList = [];
    this.jobService.getUsers().subscribe((res: any) => {
      this.companyUsers = res;
      for (let user of this.companyUsers) {
        let item = {
          item_id: user['id'],
          item_text: `${user['first_name']} ${user['last_name']} (${user['email']})`,
        };
        this.dropdownUserList.push(item);
      }
      this.dropdownUserList = new Array(this.dropdownUserList);
    });
  }
  getDefaultUser() {
    if (this.userForm.get('usertype').value == 'new') {
      this.isNewUser = true;
      this.userForm.controls['email'].setValidators([Validators.required]);
      this.userForm.controls['last_name'].setValidators([Validators.required]);
      this.userForm.controls['gender'].setValidators([Validators.required]);
      this.userForm.controls['companyName'].clearValidators();
    } else {
      this.isNewUser = false;
      this.userForm.controls['email'].clearValidators();
      this.userForm.controls['last_name'].clearValidators();
      this.userForm.controls['gender'].clearValidators();
      this.userForm.controls['companyName'].setValidators([
        Validators.required,
      ]);
    }
    this.userForm.controls['email'].updateValueAndValidity();
    this.userForm.controls['first_name'].updateValueAndValidity();
    this.userForm.controls['last_name'].updateValueAndValidity();
    this.userForm.controls['companyName'].updateValueAndValidity();
  }

  findInvalidControls() {
    const invalid = [];
    if (this.userForm.get('usertype').value == 'new') {
      const controls = this.userForm.controls;
      const cname = ['email', 'last_name', 'gender'];
      for (const name of cname) {
        if (controls[name].invalid) {
          return true;
        }
      }
    } else if (this.userForm.get('usertype').value == 'existing') {
      const controls = this.userForm.controls;
      const cname = ['companyName'];
      for (const name of cname) {
        if (controls[name].invalid) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  afterClosed($event): void {
    if (Boolean(this.user)) return this.updateUser($event);
    this.createUser($event);
  }

  createUser($event) {
    if (this.userForm.get('usertype').value == 'new') {
      const gender: string = this.userForm.value.gender;
      const userData = {
        email: this.userForm.value.email,
        first_name: this.userForm.value.first_name,
        last_name: this.userForm.value.last_name,
        gender: gender === 'null' ? null : gender,
        phone_number: this.userForm.value.phone_number,
        role: 3,
        sales: false,
      };
      this.jobService.createUser(userData).subscribe(
        (response: any) => {
          this.dialogRef.close({
            user_id: +response?.id,
            usertype: 'new',
            selectedUser: this.userForm.controls['companyName'].value,
          });
        },
        (error) => {
          $event.target.parentElement.disabled = false;
          $event.target.parentElement.classList.remove('mat-button-disabled');
          this._snackBar.open(error, 'OK', { duration: 5000 });
        }
      );
      return;
    }
    this.dialogRef.close({
      selectedUser: this.userForm.controls['companyName'].value,
      usertype: 'existing',
    });
  }

  updateUser($event) {
    const gender: string = this.userForm.value.gender;
    const userData = {
      email: this.userForm.value.email,
      first_name: this.userForm.value.first_name,
      last_name: this.userForm.value.last_name,
      gender: gender === 'null' ? null : gender,
      phone_number: this.userForm.value.phone_number,
      role: 3,
      sales: false,
    };
    if (!this.user?.id) return;
    this.jobService.updateUser(this.user.id, userData).subscribe(
      (response: any) => {
        this.dialogRef.close({
          user_id: +response?.id,
          user: userData,
        });
        this.user = null;
      },
      (error) => {
        $event.target.parentElement.disabled = false;
        $event.target.parentElement.classList.remove('mat-button-disabled');
        this._snackBar.open(error, 'OK', { duration: 5000 });
      }
    );
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
