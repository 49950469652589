<div style="float: right">
  <button mat-icon-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="job-list-div user-div">
  <mat-dialog-content class="user-dialog">
    <form [formGroup]="userForm">
      <table class="edit-form">
        <!-- User Type Selection Dropdown -->
        <tr *ngIf="!user">
          <td class="label-td" style="padding-top: 15px !important" translate>
            create_job.user_type
          </td>
          <td
            class="edit-td"
            style="padding-top: 15px !important"
            *ngIf="editMode"
          >
            <mat-radio-group formControlName="usertype">
              <mat-radio-button value="new">
                {{ "create_job.create_new_user" | translate }}
              </mat-radio-button>
              <mat-radio-button
                value="existing"
                style="margin-left: 10px"
                translate
              >
                {{ "create_job.assign_to_existing" | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </td>
          <td *ngIf="!editMode">
            {{ "create_job.assign_to_existing" | translate }}xfgdf
          </td>
        </tr>

        <!-- existing user dropdown -->

        <tr *ngIf="!isNewUser">
          <td style="padding-top: 20px !important" translate>
            create_job.user
            <span *ngIf="editMode" class="is-validate" style="color: red"
              >*</span
            >
          </td>
          <td *ngIf="editMode" style="padding-top: 20px !important">
            <ng-multiselect-dropdown
              [placeholder]="'Select user'"
              formControlName="companyName"
              [settings]="dropdownUserSettings"
              [data]="dropdownUserList[0]"
              #multiSelect
            >
            </ng-multiselect-dropdown>
          </td>
          <td *ngIf="!editMode">
            <tag *ngFor="let i of selectedUser">{{ i.item_text }}</tag>
          </td>
        </tr>

        <!-- create new user dialog Start-->
        <tr *ngIf="isNewUser">
          <td class="label-td" translate>
            create_job.email
            <span *ngIf="editMode" class="is-validate" style="color: red"
              >*</span
            >
          </td>
          <td class="edit-td" *ngIf="editMode">
            <mat-form-field appearance="legacy" style="display: inline">
              <mat-label translate>create_job.email</mat-label>
              <input matInput formControlName="email" />
            </mat-form-field>
          </td>
          <!-- <td *ngIf="!editMode">
                    {{userForm.controls['email'].value}}</td> -->
        </tr>
        <tr *ngIf="isNewUser">
          <td class="label-td" translate>Telefonnummer</td>
          <td class="edit-td" *ngIf="editMode">
            <mat-form-field appearance="legacy" style="display: inline">
              <mat-label translate>Telefonnummer</mat-label>
              <input matInput formControlName="phone_number" />
            </mat-form-field>
          </td>
          <!-- <td *ngIf="!editMode">
                    {{userForm.controls['email'].value}}</td> -->
        </tr>
        <tr *ngIf="isNewUser">
          <td class="label-td" translate>
            create_job.gender
            <span *ngIf="editMode" class="is-validate" style="color: red"
              >*</span
            >
          </td>
          <td class="edit-td">
            <mat-radio-group
              aria-labelledby="gender"
              class="gender-radio"
              formControlName="gender"
            >
              <mat-radio-button
                [checked]="[userForm.controls['gender'].value === 1]"
                value="1"
              >
                {{ "profile.male" | translate }}
              </mat-radio-button>

              <mat-radio-button
                [checked]="[userForm.controls['gender'].value === 2]"
                value="2"
                style="margin-left: 10px"
                translate
              >
                {{ "profile.female" | translate }}
              </mat-radio-button>

              <mat-radio-button
                [checked]="[!userForm.controls['gender'].value]"
                value="null"
                style="margin-left: 10px"
                translate
              >
                {{ "profile.not_specified" | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </td>
        </tr>

        <tr *ngIf="isNewUser">
          <td class="label-td" translate>create_job.first_name</td>
          <td class="edit-td" *ngIf="editMode">
            <mat-form-field appearance="legacy" style="display: inline">
              <mat-label translate>create_job.first_name</mat-label>
              <input matInput formControlName="first_name" />
            </mat-form-field>
          </td>
        </tr>
        <tr *ngIf="isNewUser">
          <td class="label-td" translate>
            create_job.last_name
            <span *ngIf="editMode" class="is-validate" style="color: red"
              >*</span
            >
          </td>
          <td class="edit-td" *ngIf="editMode">
            <mat-form-field appearance="legacy" style="display: inline">
              <mat-label translate> create_job.last_name</mat-label>
              <input matInput formControlName="last_name" />
            </mat-form-field>
          </td>
        </tr>
      </table>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color="basic" (click)="onNoClick()">
      Abbrechen
    </button>

    <button
      mat-flat-button
      color="primary"
      (click)="afterClosed($event)"
      [disabled]="findInvalidControls()"
      cdkFocusInitial
      translate
    >
      {{
        user ? ("create_job.save" | translate) : ("create_job.add" | translate)
      }}
    </button>
  </mat-dialog-actions>
</div>
