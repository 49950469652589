import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { JobUploadContentComponent } from './job-upload-content/job-upload-content.component';

@Component({
  selector: 'job-upload-csv',
  templateUrl: './job-upload-csv.component.html',
  styleUrls: ['./job-upload-csv.component.scss'],
})
export class JobUploadCsvComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog() {
    const dialogRef = this.dialog.open(JobUploadContentComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('dialog closed');
    });
  }
}
