import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { HealthFacilityService } from 'src/app/services/health-facility-service.service';

interface DialogData {
  companyId: number;
}

@Component({
  selector: 'app-health-facility-form',
  templateUrl: './health-facility-form.component.html',
  styleUrls: ['./health-facility-form.component.scss'],
})
export class HealthFacilityFormComponent implements OnInit {
  companyId: number;
  constructor(
    public dialogRef: MatDialogRef<HealthFacilityFormComponent>,
    public facilityService: HealthFacilityService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data: DialogData
  ) {
    this.companyId = data.companyId;
  }
  address = new FormGroup({
    street: new FormControl(null),
    house_number: new FormControl(null),
    city: new FormControl(null, Validators.required),
    postcode: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^\d+$/),
    ]),
    country: new FormControl('DE', Validators.required),
  });

  get city() {
    return this.address.get('city');
  }

  get postcode() {
    return this.address.get('postcode');
  }

  saveFacility() {
    if (this.address.invalid) return;
    if (!this.companyId) return;

    this.facilityService
      .createHealthFacility({
        companyId: this.companyId,
        healthFacility: { company: this.companyId, ...this.address.value },
      })
      .subscribe(
        (response) => {
          this._snackBar.open('Facility created successfuly', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            duration: 5000,
          });
          this.dialogRef.close({
            ...response,
          });
        },
        (error) => {
          this._snackBar.open(error, 'OK', { duration: 5000 });
        }
      );
  }

  ngOnInit(): void {}
}
