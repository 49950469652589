const commuincations = {
  zap: {
    b74fiay: 'send_no_response_whatsapp',
    '3o40lj0': 'send_no_response_whatsapp',
    '3bo82vm': 'Statusabfrage nach Weiterleitung [WhatsApp]',
    '3bocaos': 'Statusabfrage nach Weiterleitung [WhatsApp]',
    '3bo0mmp': 'Statusabfrage nach Weiterleitung [WhatsApp]',
    '3bojcpw': 'Statusabfrage nach Weiterleitung [WhatsApp]',
    b0c8dej: 'send_applicant_to_zapier',
    '3hxq2ia': 'Jobvorschläge gesendet [WhatsApp]',
    '3hpyqvw': 'Jobvorschläge gesendet [WhatsApp]',
    '3hpbmq6': 'Jobvorschläge gesendet [WhatsApp]',
    '3xd8rq1': 'Nachfrage Interesse [WhatsApp]',
    'https://prod-81.westeurope.logic.azure.com:443/workflows/52ab6a259d55480aade480866523c9aa/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2ftriggers%2fmanual%2frun&sv=1.0&sig=DuNrIxypSrzLz0R3SWV0xD-BzJJTm14ryHZTmiWGfs4': 'Willkommensnachricht [Mail + WhatsApp]',
    'https://prod-101.westeurope.logic.azure.com:443/workflows/e1141073c97f40ada1994e62eda0d546/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Vlsbgkq6bKphACpKy7HBKcIWWi_AyiRVYcpIA9Bvl4E': 'Statusabfrage nach Weiterleitung [WhatsApp]',
    'https://prod-115.westeurope.logic.azure.com:443/workflows/4a2b93da4b0144f29b947cb966946991/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=yYxqa3uU3UyX8ue0QxlLC4wyQgwIbKL-RA-waf6xhhk': '2. Statusabfrage nach Weiterleitung [WhatsApp]',
    'https://prod-42.westeurope.logic.azure.com:443/workflows/c6afeaafa96f477e9e1b294f1784796f/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2ftriggers%2fmanual%2frun&sv=1.0&sig=1kjGrdP6HUyyfvPUm8mQUqJoJfmnFGQsz_s6OdGdfWI': 'Neuer Jobvorschlag per Newsletter [Mail + WhatsApp]',
    'https://prod-11.westeurope.logic.azure.com:443/workflows/83d1cce4301f4d339c2a121cb6c7ba3b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=SSUkZIlYRdRY5uuwQ72OGmN8sjJO1tDqFuopQIpjgDk': 'Zugangslink zur App [WhatsApp]',
  },
  mail: {
    'd-7e8f268ca95b4829aa0ea7b62b0bed97': 'Neuer Jobvorschlag per Newsletter [Mail]',
    'd-978689a9f24041e29702e1e3d5dac79d': 'Neuer Jobvorschlag per Newsletter [Mail]',
    'c6afeaafa96f477e9e1b294f1784796f': 'Neuer Jobvorschlag per Newsletter [Mail + WhatsApp]',
    'd-1c2ab1a70d18459d8a721c33144f6777': 'Statusabfrage [Mail]',
    'd-23c51a37ac1f4cfe9f2ca4d3e4b89053': 'Reminder an ausstehende Bewerbung [Mail]',
    'd-d0470dfec23f404dbc73d801b3369253': 'Neuer Bewerbungseingang [Mail]',
    'd-44dfe5343d384f9ebb96b7aa12014442': 'Neuer Bewerbungseingang [Mail]',
    'd-62726c08dbc242cd9750765512f109fc': 'Jobvorschläge gesendet [Mail]',
    'd-446336d45d1b4b50b9c9da6b6dfb8581': 'Reminder an Jobvorschläge [Mail]',
    'd-804f0f5802e442eeb4e4414a77029cf0': 'Bestätigung der Weiterleitung [Mail]',
    'd-c53f90ade6ab492b835136635254bb7d': 'Keine Matches verfügbar [Mail]',
    'd-5950cb4267b146e593a85bbb42fb9253': 'Reminder an Jobvorschläge [WhatsApp]',
    'd-b37852a194c2464bb8f76442e353fa82': 'Keine Matches verfügbar [WhatsApp]',
    'd-fe4bff50a126483996f1305d47a97eba': 'Bestätigung der Weiterleitung [WhatsApp]',
    '52ab6a259d55480aade480866523c9aa': 'Willkommensnachricht [Mail + WhatsApp]',
  },
};

export function getCommuncationLabel(details): string {
  const service = details?.['service'];
  let key;
  if (!service) return '';
  if (service == 'mail') {
    key = details?.['template_id'];
  }
  if (service == 'zap') {
    key = details?.['url'].split('/').at(-2);
  }
  if (!key) return '';
  return commuincations[service][key];
}
