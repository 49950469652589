import { Component, OnInit, Inject } from '@angular/core';
import {
  MatBottomSheet,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.scss'],
})
export class SheetComponent implements OnInit {
  rows: any;
  key: string;
  selected: any;
  selection = new SelectionModel(true, []);

  constructor(
    private bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      rows: [{ key: string; name: string }];
      selected: string[];
      key: string;
    }
  ) {
    var first = [];
    var last = [];
    data.selected.forEach(function (item) {
      data.rows.forEach(function (e) {
        if (e.key == item) {
          first.push(e);
        }
      });
    });
    data.rows.forEach(function (item) {
      if (!data.selected.includes(item.key)) {
        last.push(item);
      }
    });
    this.selected = data.selected;
    this.rows = first.concat(last);
    this.key = data.key;
    data.selected.forEach((item) => this.selection.select(item));
  }

  ngOnInit(): void {}

  closeComponentSheetMenu() {
    this.bottomSheet.dismiss();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
    let selected = this.selection.selected;
    let selected_ids = this.rows.filter(function (item) {
      return selected.includes(item.key);
    });
    this.selected.length = 0;
    selected_ids.forEach((element) => {
      this.selected.push(element.key);
    });
    localStorage.setItem(this.key + '_rows', JSON.stringify(this.rows));
    localStorage.setItem(this.key, JSON.stringify(this.selected));
  }

  update() {
    let selected = this.selection.selected;
    let selected_ids = this.rows.filter(function (item) {
      return selected.includes(item.key);
    });
    this.selected.length = 0;
    selected_ids.forEach((element) => {
      this.selected.push(element.key);
    });
    localStorage.setItem(this.key, JSON.stringify(this.selected));
  }
}
