<div class="content">
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title style="text-align: center !important"
        >Login with</mat-card-title
      >
    </mat-card-header>

    <mat-card-content>
      <button
        mat-raised-button
        class="btn-signin"
        color="primary"
        (click)="login()"
      >
        Sign in with Microsoft
      </button>
    </mat-card-content>
  </mat-card>
</div>
