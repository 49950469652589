<mat-drawer-container class="container" [hasBackdrop]="true">
  <!-- Sidenav start -->
  <mat-drawer
    #drawer
    class="sidenav"
    position="end"
    [mode]="'side'"
    (closedStart)="startClose()"
  >
    <mat-tab-group
      animationDuration="0ms"
      [selectedIndex]="selectedTab.selected"
      (selectedIndexChange)="selectedTab.select($event)"
    >
      <mat-tab *ngIf="isNewJob || (!isNewJob && !isNewCompany)" label="Job">
        <app-job-sidebar
          *ngIf="drawer.opened"
          (updateAndClose)="updateAndClose($event)"
          (update)="update($event)"
          (close)="close($event)"
          jobId="{{ jobId }}"
          [dropdownCompanies]="dropdownCompanies"
          companyId="{{ companyId }}"
          isDublicate="{{ isDublicate }}"
          isNewJob="{{ isNewJob }}"
          (isJobUpdated)="checkIsJobUpdated($event)"
        >
        </app-job-sidebar>
      </mat-tab>
      <mat-tab
        *ngIf="isNewCompany || (!isNewJob && !isNewCompany)"
        label="Unternehmen"
      >
        <app-companysidebar
          *ngIf="drawer.opened"
          (updateAndClose)="updateAndClose($event)"
          (close)="close($event)"
          (update)="update($event)"
          companyId="{{ companyId }}"
          isNewCompany="{{ isNewCompany }}"
        >
        </app-companysidebar>
      </mat-tab>
      <mat-tab *ngIf="!isNewJob && !isNewCompany" label="Ausschreibungen">
        <app-job-posting-sidebar
          [postcode]="postcode"
          *ngIf="drawer.opened && selectedTab.isSelected(2)"
          jobId="{{ jobId }}"
        >
        </app-job-posting-sidebar>
      </mat-tab>
    </mat-tab-group>
  </mat-drawer>
  <div>
    <!-- Filter Start  -->
    <div class="row filters-row" style="margin-top: 10px">
      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          (onSelect)="queryBy('companies', selectedCompanies)"
          (onDeSelect)="queryBy('companies', selectedCompanies)"
          class="multiselect-dropdown"
          placeholder="Unternehmen"
          [settings]="dropdownSettings"
          [data]="dropdownCompanies"
          [(ngModel)]="selectedCompanies"
          [ngModelOptions]="{ standalone: true }"
        >
        </ng-multiselect-dropdown>
      </div>

      <div
        *ngIf="!isAddressActive"
        class="col filter-search-job filters-col-home"
      >
        <mat-form-field>
          <mat-label translate>jobs.search_placeholder</mat-label>
          <input
            matInput
            [(ngModel)]="searchText"
            (input)="search()"
            placeholder="{{ 'jobs.search_placeholder_value' | translate }}"
            #input
          />
        </mat-form-field>
      </div>

      <div
        *ngIf="isAddressActive"
        class="col filter-search-job filters-col-home"
      >
        <div class="col filters-col">
          <mat-form-field appearance="legacy">
            <mat-label translate>jobs.search_placeholder</mat-label>
            <input
              matInput
              ngx-google-places-autocomplete
              [options]="options"
              [placeholder]="'jobs.search_placeholder_value' | translate"
              #input
              (onAddressChange)="AddressChange($event)"
            />
            <!-- <mat-icon matSuffix>search</mat-icon> -->
          </mat-form-field>
        </div>
      </div>

      <div class="col filter-search-job filters-col-home">
        <mat-checkbox
          [(ngModel)]="isActiveproximity"
          (change)="getActiveProximity()"
          style="margin-top: 15px"
        >
          {{ 'jobs.activate_proximity' | translate }}
        </mat-checkbox>
      </div>

      <div class="col filter-search-job filters-col-home">
        <mat-checkbox
          [(ngModel)]="isActiveJobs"
          (change)="getActiveJobs()"
          style="margin-top: 15px"
        >
          {{ 'jobs.checkbox_active_jobs' | translate }}
        </mat-checkbox>
      </div>

      <div
        class="col filters-col filters-col-home"
        style="width: 100%; text-align: right"
      >
        <button
          mat-flat-button
          class="btn"
          style="margin-bottom: 15px"
          (click)="ShowFilter = !ShowFilter"
        >
          {{ 'jobs.more_filters' | translate }}
        </button>
        <button
          mat-flat-button
          class="btn"
          style="margin-bottom: 15px"
          color="primary"
          (click)="addNewCompany()"
        >
          <mat-icon>+</mat-icon> <span>Firma</span>
        </button>
        <button
          mat-flat-button
          class="btn"
          style="margin-bottom: 15px; margin-right: 12px"
          color="primary"
          (click)="addNewJob(companyId)"
        >
          <mat-icon>+</mat-icon> <span>Job</span>
        </button>
        <job-upload-csv></job-upload-csv>
      </div>
    </div>
    <!-- Weitere Filter Start -->
    <div
      class="row filters-row expand"
      [attr.colspan]="columnsToDisplay.length"
      [@detailExpand]="ShowFilter ? 'expanded' : 'collapsed'"
    >
      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          (onSelect)="queryBy('care_type', selectedCareTypeItems)"
          (onDeSelect)="queryBy('care_type', selectedCareTypeItems)"
          placeholder="{{ 'job_details.select' | translate }}  {{
            'job_details.care_type' | translate
          }}"
          [settings]="dropdownSettings"
          [data]="constants.getList('care_type') | async"
          [(ngModel)]="selectedCareTypeItems"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          placeholder="{{ 'job_details.shift' | translate }}"
          (onSelect)="queryBy('shift', selectedShiftHoursItems)"
          (onDeSelect)="queryBy('shift', selectedShiftHoursItems)"
          [settings]="dropdownSettings"
          [data]="constants.getList('shift_hour_type') | async"
          [(ngModel)]="selectedShiftHoursItems"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          (onSelect)="queryBy('working_type', selectedScopeItems)"
          (onDeSelect)="queryBy('working_type', selectedScopeItems)"
          placeholder="{{ 'job_details.select' | translate }}  {{
            'job_details.working_type' | translate
          }}"
          [settings]="dropdownSettings"
          [data]="constants.getList('shift_time_type') | async"
          [(ngModel)]="selectedScopeItems"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          (onSelect)="queryBy('position', selectedPositions)"
          (onDeSelect)="queryBy('position', selectedPositions)"
          placeholder="{{ 'job_details.select' | translate }}  {{
            'job_details.position' | translate
          }}"
          [settings]="dropdownSettings"
          [data]="constants.getList('position') | async"
          [(ngModel)]="selectedPositions"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          class="agent_user"
          (onSelect)="queryBy('agent_user', selectedAgent)"
          (onDeSelect)="queryBy('agent_user', selectedAgent)"
          placeholder="{{ 'validation.select_agent' | translate }}"
          [settings]="singleSelectWithSearch"
          [data]="dropdownAgentList"
          [(ngModel)]="selectedAgent"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          (onSelect)="queryBy('education_type', selectedEducationItems)"
          (onDeSelect)="queryBy('education_type', selectedEducationItems)"
          placeholder="{{ 'job_details.select' | translate }}  {{
            'job_details.education_type' | translate
          }}"
          [settings]="dropdownSettings"
          [data]="constants.getList('education_type') | async"
          [(ngModel)]="selectedEducationItems"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          (onSelect)="queryBy('continuing_education', selectedContiEduItems)"
          (onDeSelect)="queryBy('continuing_education', selectedContiEduItems)"
          placeholder="{{ 'job_details.select' | translate }}  {{
            'home.col_continuing_educations' | translate
          }}"
          [settings]="dropdownSettings"
          [data]="constants.getList('continuing_education_type') | async"
          [(ngModel)]="selectedContiEduItems"
        >
        </ng-multiselect-dropdown>
      </div>
    </div>

    <!--Start with table-->
    <mat-table
      class="mat-elevation-z8"
      mat-table
      multiTemplateDataRows
      matSortDisableClear
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortBy($event)"
      matSortActive="created_at"
      matSortStart="asc"
    >
      <!--add the company name-->
      <ng-container matColumnDef="companyname">
        <mat-header-cell *matHeaderCellDef mat-sort-header="company">
          {{ 'home.col_companyname' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.company.name }}
          <mat-icon
            class="company-edit"
            (click)="edit(element?.company.id, element?.id, 1, 0)"
            >edit</mat-icon
          >
        </mat-cell>
      </ng-container>

      <!--add the job title-->
      <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef mat-sort-header="city">
          {{ 'home.col_city' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.health_facility_location?.postcode }}
          {{ element?.health_facility_location?.city }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="position">
        <mat-header-cell *matHeaderCellDef> Position </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <tag *ngFor="let position of element?.position">
            {{ position | constantLabel : 'position' | async }}
          </tag>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="education">
        <mat-header-cell *matHeaderCellDef translate>
          home.col_education
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element?.education_type.length > 0">
            <tag *ngFor="let edu of element?.education_type">
              {{ edu | constantLabel : 'education_type' | async }}
            </tag>
          </ng-container>
          <ng-container *ngIf="element?.education_type?.length == 0">
            <tag>{{ 'message.not_available' | translate }}</tag>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="continuing_education">
        <mat-header-cell *matHeaderCellDef translate>
          home.col_continuing_educations
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element?.continuing_education.length">
            <tag *ngFor="let ce of element?.continuing_education">
              {{ ce[0] | constantLabel : 'continuing_education_type' | async }}
            </tag>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="caretype">
        <mat-header-cell *matHeaderCellDef translate>
          home.col_care_type
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element?.care_type?.length">
            <tag *ngFor="let ct of element?.care_type">
              {{ ct | constantLabel : 'care_type' | async }}
            </tag>
          </ng-container>
          <ng-container *ngIf="element?.care_type?.length == 0">
            <tag>{{ 'message.not_available' | translate }}</tag>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shifthour">
        <mat-header-cell *matHeaderCellDef translate>
          home.col_shift_hour
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element?.shift.length">
            <tag *ngFor="let shift of element?.shift" [color]="'secondary'">
              {{ shift | constantLabel : 'shift_hour_type' | async }}
            </tag>
          </ng-container>
          <ng-container
            *ngIf="element.shift_other != null && element.shift_other != ''"
          >
            <tag>{{ element?.shift_other }}</tag>
          </ng-container>
          <ng-container
            *ngIf="element?.shift.length == 0 && element?.shift_others == ''"
          >
            <tag>{{ 'message.not_available' | translate }}</tag>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="scope">
        <mat-header-cell *matHeaderCellDef mat-sort-header="working_type">
          {{ 'home.col_scope' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <tag *ngFor="let wt of element?.working_type" [color]="'secondary'">
            {{ wt | constantLabel : 'shift_time_type' | async }}
          </tag>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="created">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="created_at"
          translate
        >
          {{ 'home.col_created_date' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.created_at | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="agentuser">
        <mat-header-cell *matHeaderCellDef translate>
          Kundenbetreuer
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.company?.agent_user?.first_name }}
          {{ element?.company?.agent_user?.last_name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="updated">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="updated_at"
          translate
        >
          Aktualisiert
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.updated_at | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" style="text-align: end">
          <button
            mat-icon-button
            (click)="
              edit(element?.company.id, element?.id, 2, 0, element?.postcode)
            "
            *ngIf="element.job_posting_count"
          >
            <mat-icon
              class="action-icon"
              matBadge="{{ element.job_posting_count }}"
              matBadgeOverlap="true"
              matBadgeSize="small"
              >share</mat-icon
            >
          </button>
          <mat-slide-toggle
            class="action-icon"
            stytle="margin-right:5px;"
            [checked]="element?.is_active === 1"
            (change)="updateJobStatus(element?.id, $event)"
            color="primary"
          >
          </mat-slide-toggle>
          <button
            mat-icon-button
            class="action-icon ml-2"
            [matMenuTriggerFor]="menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
            <button
              mat-menu-item
              (click)="
                edit(element?.company.id, element?.id, 0, 1, element?.postcode)
              "
            >
              <mat-icon>content_copy</mat-icon>
              <span translate>jobs.duplicate</span>
            </button>

            <button
              mat-menu-item
              (click)="
                edit(element?.company.id, element?.id, 2, 0, element?.postcode)
              "
            >
              <mat-icon>share</mat-icon>
              <span translate>jobs.postings</span>
            </button>
            <button mat-menu-item (click)="delete(element?.id)">
              <mat-icon class="delete-icon">delete</mat-icon>
              <span class="delete-icon" translate>jobs.delete_job</span>
            </button>
          </mat-menu>

          <mat-icon
            class="action-icon"
            (click)="
              edit(element?.company.id, element?.id, 0, 0, element?.postcode)
            "
            >edit</mat-icon
          >
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        id="{{ element.id }}_match"
        [class.inactive]="element?.is_active == 1 ? false : true"
      ></mat-row>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="10">
          {{ 'message.no_record' | translate }}
        </td>
      </tr>
    </mat-table>
    <div
      class="mat-elevation-z8 paginator-row"
      *ngIf="dataSource.page$ | async"
    >
      <div class="progress-bar">
        <mat-progress-bar mode="buffer" *ngIf="dataSource.loading$ | async">
        </mat-progress-bar>
      </div>
      <mat-paginator
        *ngIf="dataSource.page$ | async as page"
        [length]="page.totalElements"
        [pageSize]="15"
        [showFirstLastButtons]="true"
        [pageIndex]="page.number"
        [hidePageSize]="true"
        (page)="pageEvent = handlePage($event)"
        (page)="dataSource.fetch($event.pageIndex)"
      >
      </mat-paginator>
    </div>
  </div>

  <div
    *ngIf="(dataSource.loading$ | async) !== false && !curentPage"
    class="skeleton-loader"
  >
    <ngx-skeleton-loader
      count="10"
      appearance="line"
      [theme]="{ 'height.px': 35 }"
    ></ngx-skeleton-loader>
  </div>
  <app-row-selecter
    [uniqueId]="columnKey"
    [allRows]="columnsAll"
    [selectedRows]="columnsToDisplay"
  ></app-row-selecter>
</mat-drawer-container>
