import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PostingsService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {}

  get(url, params = {}) {
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(catchError(this.handleError));
  }

  post(url, data, httpOptions = {}) {
    return this.http
      .post(
        `${environment.BASE_URL}${environment.PATH}${url}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  put(url, data, httpOptions = {}) {
    return this.http
      .put(
        `${environment.BASE_URL}${environment.PATH}${url}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }
  delete(url, httpOptions = {}) {
    return this.http
      .delete(`${environment.BASE_URL}${environment.PATH}${url}`, httpOptions)
      .pipe(catchError(this.handleError));
  }
  public handleError(errorRes: HttpErrorResponse) {
    if (Object.keys(errorRes?.error)?.length > 0) {
      if (
        Object.keys(errorRes?.error)[0]?.length > 1 &&
        errorRes?.statusText !== 'Unknown Error'
      ) {
        return throwError(
          Object.keys(errorRes.error)[0] +
            ' ' +
            Object.values(errorRes.error)[0]
        );
      }
    }
    const message = errorRes?.statusText
      ? errorRes?.statusText
      : 'etwas ist schief gelaufen!';
    return throwError(message);
  }

  addPostingData(data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.post(`jobs/jobposition/`, data, httpOptions);
  }

  editPostingData(data) {
    const httpOptions = this.authService.getHTTPOption();
    return this.put(`jobs/jobposition/${data.id}/`, data, httpOptions);
  }

  getPostingDataForJob(jobId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`jobs/jobposition/?job_id=${jobId}`, httpOptions);
  }

  getTemplateData(careType) {
    const httpOptions = this.authService.getHTTPOption();
    return this.get(`jobs/template/?job_caretype=${careType}`, httpOptions);
  }

  getPostingDelete(jobId) {
    const httpOptions = this.authService.getHTTPOption();
    return this.delete(`jobs/jobposition/${jobId}/`, httpOptions);
  }
}
