<mat-toolbar>
  <span translate>header.title</span>
  <span class="example-spacer"></span>
  <a
    [routerLink]="['/applicants']"
    *ngIf="isAjgentLogin && isMobile == false"
    [routerLinkActiveOptions]="{ exact: true }"
    routerLinkActive="is-active"
    class="btn header-btn"
  >
    <mat-icon>groups</mat-icon><span translate>header.home</span>
  </a>
  <a
    [routerLink]="['/jobs']"
    *ngIf="isAjgentLogin && isMobile == false"
    [routerLinkActive]="['is-active']"
    class="btn header-btn"
  >
    <mat-icon>holiday_village</mat-icon><span translate>header.jobs</span>
  </a>

  <a
    [routerLink]="['/dashboard']"
    *ngIf="isACuserLogin && isMobile == false"
    [routerLinkActive]="['is-active']"
    class="btn header-btn"
  >
    <mat-icon>maps_home_work</mat-icon><span translate>Standorte</span>
  </a>
  <a
    [routerLink]="['/forwardings']"
    *ngIf="isAjgentLogin && isMobile == false"
    [routerLinkActive]="['is-active']"
    class="btn header-btn"
  >
    <mat-icon>connect_without_contact</mat-icon
    ><span translate>Weiterleitungen</span>
  </a>

  <a
    [routerLink]="['/my-profile']"
    *ngIf="isACuserLogin && isMobile == false"
    [routerLinkActive]="['is-active']"
    class="btn header-btn"
  >
    <mat-icon>person</mat-icon><span translate>header.profile</span>
  </a>
  <a
    [routerLink]="['/companies']"
    *ngIf="isAjgentLogin && isMobile == false"
    [routerLinkActive]="['is-active']"
    class="btn header-btn"
  >
    <mat-icon>apartment</mat-icon><span translate>Unternehmen</span>
  </a>

  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    *ngIf="(isAjgentLogin || isACuserLogin) && isMobile == false"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      [routerLink]="['/map']"
      *ngIf="isAjgentLogin"
      [routerLinkActive]="['is-active']"
    >
      <mat-icon>map</mat-icon><span translate>header.map</span>
    </button>

    <button
      mat-menu-item
      [routerLink]="['/postings']"
      *ngIf="isAjgentLogin"
      [routerLinkActive]="['is-active']"
    >
      <mat-icon>share</mat-icon><span translate>Auschreibungen</span>
    </button>
    <button
      mat-menu-item
      [routerLink]="['/my-profile']"
      *ngIf="isAjgentLogin || isACuserLogin"
      [routerLinkActive]="['is-active']"
    >
      <mat-icon>person</mat-icon><span translate>header.profile</span>
    </button>
    <button mat-menu-item *ngIf="isLoginPage" (click)="logout()">
      <mat-icon>logout</mat-icon><span translate>header.logout</span>
    </button>
  </mat-menu>
</mat-toolbar>
