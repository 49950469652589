import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { of, throwError } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTable } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { JobService } from 'src/app/services/job.service';
import { AddCompanyUserComponent } from '../../common/add-company-user/add-company-user.component';
import { AuthService } from 'src/app/services/auth.service';
import { Impersonation } from 'src/app/models/impersonation.model';
import { environment } from 'src/environments/environment';

interface User {
  id: number;
  first_name: string;
  last_name: string;
  name: string;
  contactuser: boolean;
  email: string;
  phone: string;
  gender: number;
}

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss'],
})
export class CompanyDetailComponent implements OnInit {
  @Output() refreshCompany = new EventEmitter<any>();
  @ViewChild('drawer') drawer: MatSidenav;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: User[];
  selectedTab = new SelectionModel(false, [0]);

  columnsToDisplay = [
    'name',
    'email',
    'phone',
    'gender',
    'contactuser',
    'action',
  ];
  companyDetails: any = {};
  isChecked: boolean;
  match: any;
  companyDetailsForm: FormGroup;
  company_presentation: any;
  company_benefit: any;
  companyForm: FormGroup;
  usertype: any;
  userRole: number;
  companyUser: any;
  user_id: any;
  isSpinner: boolean = false;
  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  dropdownUserSettings: IDropdownSettings;
  dropdownSingleSettings: IDropdownSettings;
  dropdownAgentList = [];
  selectedUser = [];
  selectedAgent = [];
  companyUsers = [];
  companyAgent = [];
  images: any;
  logo_png: string;
  logo_webp: string;
  gallery_png: string;
  gallery_webp: string;
  companyId: number;

  constructor(
    private jobService: JobService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private cdref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    let userData = JSON.parse(localStorage.getItem('agentData'))
      ? JSON.parse(localStorage.getItem('agentData'))
      : JSON.parse(localStorage.getItem('companyData'));
    this.userRole = userData.role;
  }

  ngOnInit(): void {
    this.initForm();
    this.initEditForm();

    this.route.params.subscribe(params => {
      this.cdref.detectChanges();
      this.isChecked = this.companyDetails.sales;
      if (!params['id']) return;
      this.companyId = +params['id'];

      this.getCompanyUsers();
      this.getCompanyDetails();
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  initForm() {
    this.companyDetailsForm = new FormGroup({
      company_presentation: new FormControl(null),
      benefits: new FormControl(null),
    });
  }

  getGender(user) {
    const genders = {
      1: 'männlich',
      2: 'weiblich',
    };
    return genders[user?.gender] ?? 'keine Angabe';
  }

  getCompanyUsers() {
    if (!this.companyId) return;
    this.jobService
      .getCompanyUser({ company_id: this.companyId })
      .subscribe((res: User[]) => {
        this.dataSource = res;
      });
  }

  updatePresentation($event) {
    this.company_presentation = $event.company_presentation;
  }

  updateBenefit($event) {
    this.company_benefit = $event.benefits;
  }

  initEditForm() {
    this.companyForm = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      companyName: new FormControl('', Validators.required),
      agent_user: new FormControl(''),
      website: new FormControl('', Validators.pattern(this.reg)),
      usertype: new FormControl('new'),
      external_name: new FormControl(''),
      phone_number: new FormControl(''),
      first_name: new FormControl(''),
      last_name: new FormControl(''),
      email: new FormControl(''),
      gender: new FormControl(''),
      about: new FormControl(''),
      sales: new FormControl(false),
      company_size: new FormControl(null),
      company_presentation: new FormControl(null),
      benefits: new FormControl(null),
      send_reminder: new FormControl(''),
    });

    if (this.userRole == 2) {
      this.companyForm.controls['agent_user'].setValidators([
        Validators.required,
      ]);
      this.companyForm.controls['agent_user'].updateValueAndValidity();
    }

    this.dropdownUserSettings = {
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'W�hlen Sie Alle',
      unSelectAllText: 'Alles wiederufen',
      itemsShowLimit: 6,
      enableCheckAll: false,
      allowSearchFilter: true,
    };
    this.dropdownSingleSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'W�hlen Sie Alle',
      unSelectAllText: 'Alles wiederufen',
      itemsShowLimit: 6,
      enableCheckAll: false,
      allowSearchFilter: true,
    };
    this.getAgentList();
  }
  getAgentList() {
    this.jobService.getAgent(false).subscribe((res: any) => {
      this.companyAgent = res;
      this.dropdownAgentList = (res || []).map(agent => ({
        item_id: agent.id,
        item_text: `${agent.first_name} ${agent.last_name}`,
      }));
    });
  }

  getCompanyDetails() {
    let sesstionResult = JSON.parse(localStorage.getItem('agentData'))
      ? JSON.parse(localStorage.getItem('agentData'))
      : JSON.parse(localStorage.getItem('companyData'));
    let userArr = [];
    if (isNaN(this.companyId)) return;
    this.isSpinner = true;
    this.companyForm?.get('companyName').clearValidators();
    this.companyForm?.get('companyName').updateValueAndValidity();
    this.jobService.getCompanyDetails(this.companyId).subscribe((res: any) => {
      this.companyDetails = res;
      if (this.companyDetails?.user) {
        for (let user of this.companyDetails?.user) {
          if (sesstionResult.id !== user?.id) {
            let userObj = {
              item_id: user.id,
              item_text: `${user['first_name']} ${user['last_name']} (${user['email']})`,
            };
            userArr.push(userObj);
          }
        }
        this.selectedUser = userArr;
        this.companyForm.patchValue({ companyName: userArr });
      }
      this.selectedAgent = [];
      if (this.companyDetails?.agent_user) {
        let agentObj = {
          item_id: this.companyDetails?.agent_user?.id,
          item_text:
            this.companyDetails?.agent_user?.first_name +
            ' ' +
            this.companyDetails?.agent_user?.last_name,
        };
        this.selectedAgent.push(agentObj);
        this.companyForm.patchValue({ agent_user: this.selectedAgent });
      }

      this.companyForm.patchValue({
        id: this.companyDetails.id,
        name: this.companyDetails.name,
        website: this.companyDetails.website,
        external_name: this.companyDetails.external_name,
        phone_number: this.companyDetails.phone_number,
        about: this.companyDetails.about,
        gender: this.companyDetails.gender,
        sales: this.companyDetails.sales,
        company_size: this.companyDetails.company_size,
        send_reminder: this.companyDetails.send_reminder,
      });
      this.isSpinner = false;
      this.companyDetailsForm.patchValue({
        company_presentation: this.companyDetails.company_presentation,
        benefits: this.companyDetails.benefits,
      });
      (this.company_presentation = this.companyDetails.company_presentation),
        (this.company_benefit = this.companyDetails.benefits);
    });
  }
  updateContactUser(user_id, $event) {
    let data = {
      company_id: +this.companyId,
      user_id: user_id,
      contact_user: $event.checked,
    };

    this.jobService.updateContactUserStatus(data).subscribe(res => {
      this.getCompanyUsers();
    });
  }

  doUpdateCompany($event) {
    if ($event?.target) {
      $event.target.parentElement.disabled = true;
      $event.target.parentElement.classList.add('mat-button-disabled');
    }

    const loginUser = JSON.parse(localStorage.getItem('agentData'));
    const cid = !isNaN(this.companyId) ? this.companyId : loginUser.id;
    let userList = [];
    for (let user of this?.selectedUser) {
      userList.push(user.item_id);
    }

    if (this.companyForm.value.usertype == 'new') {
      if (this.user_id) {
        userList.push(this.user_id);
      }

      const companyUpdateArray = [];
      const data = {
        name: this.companyForm.value.name,
        website: this.companyForm.value.website,
        phone_number: this.companyForm.value.phone_number,
        about: this.companyForm.value.about,
        external_name: this.companyForm.value.external_name,
        company_size: this.companyForm.value?.company_size,
        company_presentation: this.company_presentation,
        benefits: this.company_benefit,
        send_reminder: this.companyDetails.send_reminder,
        user: userList,
      };
      if (this.userRole == 2) {
        companyUpdateArray.push({
          ...data,
          agent_user: this.companyForm.value?.agent_user[0]['item_id'],

          sales: this.companyForm.value?.sales,
        });
      } else {
        companyUpdateArray.push({
          ...data,
          sales: false,
        });
      }
      this.jobService
        .updateCompanyDetailsV2(cid, companyUpdateArray[0])
        .subscribe(
          (response: any) => {
            this.refreshCompany.emit(response);
            this.companyDetails = response;

            this.isChecked = response.sales;
            this.getCompanyUsers();
            this.getAgentList();
            this.getCompanyDetails();

            if ($event?.target) {
              $event.target.parentElement.disabled = false;
              $event.target.parentElement.classList.remove(
                'mat-button-disabled'
              );
              this._snackBar.open(
                this.translate.instant('message.company_details_update'),
                '',
                { duration: 5000 }
              );
            }
          },
          errorRes => {
            this._snackBar.open(
              errorRes,
              this.translate.instant('message.btn_ok'),
              { duration: 5000 }
            );
          }
        );
    } else {
      const companyUpdateArray = [];
      if (this.userRole == 2) {
        companyUpdateArray.push({
          name: this.companyForm.value.name,
          website: this.companyForm.value.website,
          phone_number: this.companyForm.value.phone_number,
          about: this.companyForm.value.about,
          external_name: this.companyForm.value.external_name,
          agent_user: this.companyForm.value?.agent_user[0]['item_id'],
          user: userList,
          sales: this.companyForm.value?.sales,
          company_size: this.companyForm.value?.company_size,
          company_presentation: this.company_presentation,
          benefits: this.company_benefit,
          send_reminder: this.companyDetails.send_reminder,
        });
      } else {
        companyUpdateArray.push({
          name: this.companyForm.value.name,
          website: this.companyForm.value.website,
          phone_number: this.companyForm.value.phone_number,
          about: this.companyForm.value.about,
          external_name: this.companyForm.value.external_name,
          user: userList,
          sales: false,
          company_size: this.companyForm.value?.company_size,
          company_presentation: this.company_presentation,
          benefits: this.company_benefit,
          send_reminder: this.companyDetails.send_reminder,
        });
      }
      this.jobService
        .updateCompanyDetailsV2(cid, companyUpdateArray[0])
        .subscribe(
          (response: any) => {
            this.refreshCompany.emit(response);
            this.companyDetails = response;

            this.isChecked = response.sales;
            this.getCompanyUsers();
            this.getAgentList();
            this.getCompanyDetails();
            if ($event?.target) {
              $event.target.parentElement.disabled = false;
              $event.target.parentElement.classList.remove(
                'mat-button-disabled'
              );
            }
          },
          errorRes => {
            if ($event?.target) {
              $event.target.parentElement.disabled = false;
              $event.target.parentElement.classList.remove(
                'mat-button-disabled'
              );
              this._snackBar.open(
                errorRes,
                this.translate.instant('message.btn_ok'),
                { duration: 5000 }
              );
            }
          }
        );
    }
  }

  addCompanyDialog() {
    this.cdref.detectChanges();

    const dialogRef = this.dialog.open(AddCompanyUserComponent, {
      data: {
        editMode: true,
        companyForm: this.companyForm,
        usertype: this.usertype,
        selectedUser: this.selectedUser,
        dropdownUserSettings: this.dropdownUserSettings,
        isSpinner: this.isSpinner,
        companyUsers: this.companyUsers,
        user_id: this.user_id,
      },
      panelClass: 'myClass',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedUser = result?.selectedUser ? result?.selectedUser : [];
        this.user_id = result?.user_id ? result?.user_id : '';
        this.doUpdateCompany('');
        this.companyForm.patchValue({
          usertype: result?.usertype,
          companyName: result?.selectedUser ? result?.selectedUser : '',
        });
        result?.usertype == 'existing'
          ? this.companyForm.controls['companyName'].setValidators([
              Validators.required,
            ])
          : this.companyForm.controls['companyName'].clearValidators();
        this.companyForm.controls['companyName'].updateValueAndValidity();
      }
    });
  }

  editCompanyUser(user: User) {
    this.cdref.detectChanges();
    const dialogRef = this.dialog.open(AddCompanyUserComponent, {
      data: {
        editMode: true,
        user: user,
      },
      panelClass: 'myClass',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      const index = (this.dataSource ?? []).findIndex(
        user => user.id === result.user_id
      );

      if (index === -1) return;
      this.dataSource[index] = {
        ...result.user,
        id: result?.user_id,
        phone: result?.user?.phone_number,
        contactuser: this.dataSource[index]?.contactuser,
      };
      this.table.renderRows();
    });
  }

  impersonateUserOnCompanyApp(userId: number) {
    const agentToken = this.authService.getAuthToken();

    if (!agentToken) {
      this._snackBar.open(
        this.translate.instant('message.unable_to_impersonate'),
        this.translate.instant('message.btn_ok'),
        {
          duration: 5000,
        }
      );
      return;
    }

    this.authService
      .getUserImpersonationToken(userId)
      .pipe(
        take(1),
        switchMap((resp: Impersonation) => {
          if (!resp) return throwError('No impersonation token found');

          if (!resp.impersonation_token) return throwError('No token found');

          const token = resp.impersonation_token.split(' ').pop();

          const companyAppUrl = `${environment.laenkApps.company.url}/auth/impersonate?impersonationToken=${token}&agentToken=${agentToken}`;

          window.open(companyAppUrl, '_blank', 'noopener noreferrer');

          return of(undefined);
        })
      )
      .subscribe({
        error: err => {
          this._snackBar.open(
            this.translate.instant('message.unable_to_impersonate'),
            this.translate.instant('message.btn_ok'),
            {
              duration: 5000,
            }
          );
        },
      });
  }
}
