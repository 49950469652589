import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  CanActivate,
  CanDeactivate,
  CanLoad,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyGuard implements CanActivate {
  isMobile = false;
  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let response = JSON.parse(localStorage.getItem('companyData'));
    this.isMobile = this.deviceService.isMobile();
    if (this.isMobile === true) {
      this.router.navigate(['mobile']);
      return false;
    } else if (!response) {
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
    return true;
  }
}
