<div *ngIf="!isSpinner" class="job-list-div">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="jobForm">
        <table class="edit-form">
          <tr>
            <td class="label-td" translate>
              job_details.position
              <span *ngIf="editMode" class="is-validate">*</span>
            </td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown
                formControlName="position"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="multiSelectWithSearch"
                [data]="constants.getList('position') | async"
                [(ngModel)]="selectedPosition"
                (onSelect)="checkValueChange()"
                (onDeSelect)="checkValueChange()"
              >
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode" class="edit-label">
              <tag *ngFor="let i of selectedPosition">{{ i.label }}</tag>
            </td>
          </tr>

          <tr *ngIf="jobForm.controls['position_other'].value">
            <td class="label-td" translate>
              job_details.position_other
              <span *ngIf="editMode" class="is-validate"></span>
            </td>

            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  formControlName="position_other"
                  (change)="checkValueChange()"
                />
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{ jobForm.controls['position_other'].value }}
            </td>
          </tr>
          <tr></tr>
          <tr *ngIf="isNewJob === 'true'">
            <td class="label-td" translate>
              job_details.company
              <span *ngIf="editMode" class="is-validate">*</span>
            </td>
            <td class="edit-td" *ngIf="editMode">
              <div class="col filters-col">
                <ng-multiselect-dropdown
                  #multiSelect
                  (onSelect)="checkValueChange()"
                  (onDeSelect)="checkValueChange()"
                  formControlName="companyName"
                  class="multiselect-dropdown"
                  placeholder="{{ 'job_details.not_selected' | translate }}"
                  [settings]="singleSelectWithSearch"
                  [data]="dropdownCompanies"
                  [(ngModel)]="selectedCompanies"
                >
                </ng-multiselect-dropdown>
              </div>
            </td>
            <td *ngIf="!editMode" class="edit-label">
              <tag *ngFor="let i of selectedCompanies">{{ i.label }}</tag>
            </td>
          </tr>

          <div class="spacer"></div>
          <tr style="position: relative">
            <td class="label-td" translate>
              Standort
              <span *ngIf="editMode" class="is-validate">*</span>
            </td>
            <td class="edit-td">
              <mat-form-field>
                <mat-select
                  formControlName="facility"
                  [disabled]="isNewJob === 'true' && !companyName.value.length"
                >
                  <mat-option
                    *ngFor="let facility of facilities"
                    [value]="facility.id"
                  >
                    {{ facility?.postcode }}
                    {{ facility?.street }}
                    {{ facility?.city }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button
                (click)="createFacility()"
                class="create-facility"
                [disabled]="isNewJob === 'true' && !companyName.value.length"
              >
                Standort hinzufüge
              </button>
            </td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.title</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <mat-label translate>job_details.title </mat-label>
                <input
                  matInput
                  formControlName="title"
                  (change)="checkValueChange()"
                  maxlength="50"
                />
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{ jobForm.controls['title'].value }}
            </td>
          </tr>

          <tr>
            <td class="label-td" translate>job_details.website</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  formControlName="url"
                  (change)="checkValueChange()"
                />
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{ jobForm.controls['url'].value }}
            </td>
          </tr>

          <tr>
            <td class="label-td" translate>
              job_details.shift
              <span *ngIf="editMode" class="is-validate">*</span>
            </td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown
                #multiSelect
                formControlName="shifts"
                (onDeSelect)="checkValueChange()"
                (onSelect)="checkValueChange()"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="dropdownSettings"
                [data]="constants.getList('shift_hour_type') | async"
                [(ngModel)]="selectedShiftHoursItems"
              >
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode" class="edit-label">
              <tag *ngFor="let i of selectedShiftHoursItems">{{ i.label }}</tag>
            </td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.shift_other</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy" class="drawer-mat-field">
                <input
                  matInput
                  type="text"
                  (change)="checkValueChange()"
                  formControlName="shift_other"
                />
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{ jobForm.controls['shift_other'].value }}
            </td>
          </tr>

          <tr>
            <td class="label-td" translate>job_details.salary_range</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field
                appearance="legacy"
                class="drawer-mat-field"
                style="width: 35%"
                *ngIf="isShowSalary"
              >
                <input
                  matInput
                  class="ng-invalid"
                  type="number"
                  max="maxSalary"
                  [(ngModel)]="minSalary"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="checkSalary(minSalary, maxSalary)"
                  (keyup)="checkSalary(minSalary, maxSalary)"
                />
              </mat-form-field>
              <mat-icon
                *ngIf="isShowSalary"
                style="margin-left: 20px; margin-right: 20px"
                >settings_ethernet</mat-icon
              >
              <mat-form-field
                appearance="legacy"
                class="drawer-mat-field"
                style="width: 35%"
                *ngIf="isShowSalary"
              >
                <input
                  matInput
                  type="number"
                  min="1"
                  [(ngModel)]="maxSalary"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="checkSalary(minSalary, maxSalary)"
                  (keyup)="checkSalary(minSalary, maxSalary)"
                />
              </mat-form-field>
              <button
                mat-icon-button
                color="primary"
                style="width: 7%; float: right"
                (click)="showSalary(!isShowSalary)"
              >
                <mat-icon>{{ isShowSalary ? 'delete' : 'add' }}</mat-icon>
              </button>
            </td>
          </tr>
          <tr
            style="height: unset"
            *ngIf="jobForm.controls['min_salary'].errors"
          >
            <td></td>
            <td>
              <mat-hint class="postcode-invalid">
                <p style="margin: -10px 0 12px" translate>
                  Das Mindestgehalt sollte nicht höher sein als das Maximum
                </p>
              </mat-hint>
            </td>
          </tr>
          <tr>
            <td class="label-td" translate>
              job_details.care_type
              <span *ngIf="editMode" class="is-validate">*</span>
            </td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown
                #multiSelect
                formControlName="careTypes"
                (onDeSelect)="checkValueChange()"
                (onSelect)="checkValueChange()"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="multiSelectWithSearch"
                [data]="constants.getList('care_type') | async"
                [(ngModel)]="selectedCareTypeItems"
              >
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode" class="edit-label">
              <tag *ngFor="let i of selectedCareTypeItems">{{ i.label }}</tag>
            </td>
          </tr>
          <tr>
            <td class="label-td" translate>
              job_details.working_type
              <span *ngIf="editMode" class="is-validate">*</span>
            </td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown
                #multiSelect
                formControlName="workingTypes"
                (onSelect)="checkValueChange()"
                (onDeSelect)="checkValueChange()"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="dropdownSettings"
                [data]="constants.getList('shift_time_type') | async"
                [(ngModel)]="selectedScopeItems"
              >
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode" class="edit-label">
              <tag *ngFor="let i of selectedScopeItems">{{ i.label }}</tag>
            </td>
          </tr>

          <tr>
            <td class="label-td" translate>job_details.education_type</td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown
                #multiSelect
                formControlName="educationTypes"
                (onSelect)="checkValueChange()"
                (onDeSelect)="checkValueChange()"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="multiSelectWithSearch"
                [data]="constants.getList('education_type') | async"
                [(ngModel)]="selectedEducationItems"
              >
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode" class="edit-label">
              <tag *ngFor="let i of selectedEducationItems">{{ i.label }}</tag>
            </td>
          </tr>

          <tr>
            <td class="label-td" translate>job_details.continuing_education</td>
            <td *ngIf="editMode">
              <ng-multiselect-dropdown
                formControlName="continuing_education"
                (onSelect)="checkValueChange()"
                (onDeSelect)="checkValueChange()"
                class="multiselect-dropdown"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="multiSelectWithSearch"
                [data]="constants.getList('continuing_education_type') | async"
                [(ngModel)]="selectedContinuingEducation"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode" class="edit-label">
              <tag *ngFor="let i of selectedContinuingEducation">{{
                i.label
              }}</tag>
            </td>
          </tr>

          <tr>
            <td class="label-td" translate>
              job_details.specification_care_items
            </td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown
                (onSelect)="checkValueChange()"
                (onDeSelect)="checkValueChange()"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="dropdownSettings"
                [data]="
                  constants.getList('specification_job_care_type') | async
                "
                [(ngModel)]="specificationCareItems"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode" class="edit-label">
              <tag *ngFor="let i of specificationCareItems">{{ i.label }}</tag>
            </td>
          </tr>
          <tr>
            <td class="label-td" translate>
              job_details.special_requirement_job_type
            </td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown
                (onSelect)="checkValueChange()"
                (onDeSelect)="checkValueChange()"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="dropdownSettings"
                [data]="
                  constants.getList('special_requirement_job_type') | async
                "
                [(ngModel)]="specialRequirementJobTypeItems"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode" class="edit-label">
              <tag *ngFor="let i of specialRequirementJobTypeItems">{{
                i.label
              }}</tag>
            </td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.note</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  formControlName="note"
                  (change)="checkValueChange()"
                />
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{ jobForm.controls['note'].value }}
            </td>
          </tr>
          <tr *ngIf="userRole == 2">
            <td class="label-td" translate>job_details.internal_note</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  formControlName="internal_note"
                  (change)="checkValueChange()"
                />
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{ jobForm.controls['internal_note'].value }}
            </td>
          </tr>

          <tr class="btn_active" *ngIf="jobDetails?.kombo_job && !editMode">
            <td class="label-td" translate>job_details.title</td>
            <p>{{ jobDetails?.kombo_job?.name }}</p>

            <br />
          </tr>

          <tr class="btn_active" *ngIf="jobDetails?.kombo_job && !editMode">
            <td class="label-td" translate>job_details.jobDescription</td>

            <div [innerHTML]="jobDetails?.kombo_job?.description"></div>

            <br />
          </tr>

          <tr class="btn_active">
            <td class="label-td" translate>
              job_details.job_status
              <span *ngIf="editMode" class="is-validate">*</span>
            </td>
            <td class="edit-td" *ngIf="editMode">
              <mat-select formControlName="status">
                <mat-option
                  *ngFor="let status of constants.getList('job_status') | async"
                  [value]="status.id"
                  (onSelectionChange)="checkValueChange()"
                >
                  {{ status.label }}
                </mat-option>
              </mat-select>
            </td>

            <td *ngIf="!editMode">
              {{
                jobForm.controls['status'].value
                  | constantLabel : 'job_status'
                  | async
              }}
            </td>

            <br />
          </tr>
        </table>
      </form>
    </mat-card-content>

    <div class="footer" #footer translate>
      <button
        [disabled]="jobForm.invalid || !isValueChange"
        mat-flat-button
        color="primary"
        *ngIf="isNewJob == 'true' || isNewJob == 'false'"
        (click)="doUpdateJob((isClose = true), $event)"
      >
        {{ 'jobs.btn_save_and_close' | translate }}
      </button>
      &nbsp;
      <button
        [disabled]="jobForm.invalid"
        mat-flat-button
        *ngIf="isNewJob == 'true'"
        color="primary"
        (click)="doUpdateJob((isClose = true), $event)"
      >
        {{ 'jobs.btn_save' | translate }}</button
      >&nbsp;
      <button
        [disabled]="jobForm.invalid || !isValueChange"
        mat-flat-button
        color="primary"
        *ngIf="isNewJob == 'false'"
        (click)="doUpdateJob((isClose = false), $event)"
      >
        {{ 'jobs.btn_save' | translate }}
      </button>
      <button mat-flat-button (click)="resetData()">
        <span translate> jobs.btn_reset </span>
      </button>
    </div>
  </mat-card>
</div>
<ng-container *ngIf="isSpinner">
  <ngx-skeleton-loader
    count="2"
    appearance="line"
    [theme]="{ 'height.px': 100 }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    count="5"
    appearance="line"
    [theme]="{ 'height.px': 50 }"
  ></ngx-skeleton-loader>
</ng-container>
