<div *ngIf="histories" class="bar">
  <ng-container *ngIf="siblingApplierId">
    <button
      mat-icon-button
      class="icon-button"
      aria-label="back button"
      (click)="siblingApplierId = null"
    >
      <mat-icon> arrow_back </mat-icon>
    </button>
    <app-applier-sidebar
      [applierId]="siblingApplierId"
      [editMode]="false"
    ></app-applier-sidebar>
  </ng-container>

  <div [hidden]="siblingApplierId">
    {{ siblingApplierId }}
    <h1 class="drawer-title">{{ historyData?.name }}</h1>

    <ng-multiselect-dropdown
      placeholder="{{ 'home.flags' | translate }}"
      [settings]="dropdownSettings"
      [(ngModel)]="selectedFlag"
      [data]="constants.getList('applier_flag') | async"
      (onSelect)="onFlagSelect($event)"
      (onDeSelect)="onFlagDeSelect($event)"
      #multiSelect
    >
    </ng-multiselect-dropdown>

    <form class="applier-status" (ngSubmit)="updateApplierStatus()">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select [(ngModel)]="selectedApplierStatus" name="Status">
          <mat-option
            *ngFor="let stati of constants.getList('applier_status') | async"
            [value]="stati.id"
          >
            {{ stati?.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        style="margin-left: 16px"
      >
        {{ 'match-history.btn_send' | translate }}
      </button>
    </form>

    <form [formGroup]="historyForm" (ngSubmit)="submitHistory()">
      <mat-form-field class="width100">
        <mat-label translate>history.description</mat-label>
        <input matInput formControlName="content" />
      </mat-form-field>
      <mat-form-field>
        <mat-label translate>Template</mat-label>
        <mat-select formControlName="status_id">
          <mat-option *ngFor="let status of status" [value]="status.id">
            {{ status?.status_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label translate>history.channel</mat-label>
        <mat-select formControlName="channel_id" class="channel_id">
          <mat-select-trigger>
            <mat-icon
              class="selected-icon whatsapp-icon"
              *ngIf="icn === 'whatsapp'; else elseBlock"
            >
            </mat-icon>
            <ng-template #elseBlock>
              <mat-icon class="selected-icon">{{ icn }}</mat-icon>
            </ng-template>
            &nbsp;{{ chnlnm }}
          </mat-select-trigger>
          <mat-option
            (onSelectionChange)="
              changeIconNname($event, channel.icon, channel?.channel_name)
            "
            *ngFor="let channel of channels"
            [value]="channel.id"
            (click)="updateDate(channel)"
          >
            <mat-icon
              class="whatsapp-icon"
              *ngIf="channel.icon === 'whatsapp'; else elseBlock"
            >
            </mat-icon>

            <ng-template #elseBlock>
              <mat-icon>{{ channel.icon }}</mat-icon>
            </ng-template>
            {{ channel?.channel_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="width: 27.5%">
        <mat-label>nächste Aktion</mat-label>
        <input
          matInput
          [ngxMatDatetimePicker]="picker"
          placeholder="Choose a date"
          formControlName="due_date"
          [min]="minDate"
          [disabled]="!isDueDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [hideTime]="true">
          <!-- Custom icon or text of Apply icon -->
          <ng-template>
            <!-- <mat-icon>star</mat-icon> -->
            <span>OK</span>
          </ng-template>
        </ngx-mat-datetime-picker>
      </mat-form-field>

      <button type="submit" mat-flat-button color="primary">
        {{ 'history.btn_send' | translate }}
      </button>
    </form>
    <mat-divider class="divider"></mat-divider>
    <div style="height: 8px"></div>

    <ng-container *ngIf="historyData?.length == 0">
      <p class="text-center">
        <b>{{ 'message.no_record' | translate }}</b>
      </p>
    </ng-container>

    <!-- match history and applier history -->
    <div *ngFor="let history of histories | keyvalue : recentApplicant">
      <div class="card-title">
        Bewerbung:
        <time [datetime]="history.key" *ngIf="history?.value?.length">
          {{ getDate(history.value) | date : 'dd.MM.yyyy, HH:mm' }}
        </time>
        <button
          mat-icon-button
          class="icon-button"
          aria-label="icon button with a person icon"
          (click)="addApplicantDrawer(history?.value)"
        >
          <mat-icon> person </mat-icon>
        </button>
      </div>
      <mat-card *ngFor="let log of history.value; first as isFirst">
        <input
          type="hidden"
          id="historyid"
          name="historyid"
          value="{{ log.id }}"
        />
        <div class="card-image">
          <mat-icon
            class="whatsapp-icon"
            *ngIf="
              log?.status_log?.channel?.icon === 'whatsapp';
              else statusIcon
            "
          >
          </mat-icon>

          <ng-template #statusIcon>
            <mat-icon
              *ngIf="
                log?.old_status !== (log?.status || log?.new_status);
                else flagIcon
              "
            >
              query_builder
            </mat-icon>
          </ng-template>

          <ng-template #flagIcon>
            <mat-icon *ngIf="log.type === 'FLAG'; else communicationIcon">
              flag
            </mat-icon>
          </ng-template>

          <ng-template #communicationIcon>
            <mat-icon *ngIf="log.type === 'COMMUNICATION'; else elseBlock">
              forum
            </mat-icon>
          </ng-template>

          <ng-template #elseBlock>
            <mat-icon>{{ log?.status_log?.channel?.icon }}</mat-icon>
          </ng-template>
        </div>
        <div class="content">
          <mat-card-subtitle class="no-margin">
            {{ log?.created_at | date : 'dd.MM.yyyy, HH:mm' }}
            |
            <span
              matTooltip="erstellt von"
              *ngIf="log?.actor == 'SYSTEM'; else actor"
            >
              {{ 'System' }}
            </span>
            <ng-template #actor>
              <span>
                {{ (log?.user ?? log?.created_by)?.first_name }}
                {{ (log?.user ?? log?.created_by)?.last_name }}
                ({{ getActorLabel(log?.actor)?.label ?? '' }})
              </span>
            </ng-template>
          </mat-card-subtitle>
          <mat-card-content *ngIf="log.type === 'FLAG'; else communication">
            {{ flagLabel(log) }}
          </mat-card-content>

          <ng-template #communication>
            <mat-card-content
              *ngIf="log.type === 'COMMUNICATION'; else registration"
            >
              Eine E-mail / WhatsApp Nachricht wurde verschickt:
              {{ communcationLabel(log?.details) }}
            </mat-card-content>
          </ng-template>

          <ng-template #registration>
            <mat-card-content
              *ngIf="log.type === 'REGISTRATION'; else status_log"
            >
              Der Bewerber hat sich registriert
            </mat-card-content>
          </ng-template>

          <ng-template #status_log>
            <div *ngIf="log?.details?.status_id; else fieldChange">
              <div class="status-card">
                <mat-card-content
                  class="{{ log?.content ? 'no-margin' : 'm3-margin' }}"
                  >{{ log?.status_log?.status?.status_name }}
                </mat-card-content>
                <div class="status-card right">
                  <div style="display: flex; align-items: center">
                    <button
                      class="whatsapp-icon"
                      matTooltip="{{ log?.status?.whatsapp_tooltip }}"
                      (click)="
                        sendMatches(
                          7,
                          log?.applier?.id,
                          log?.user?.whatsapp_number ?? log?.user?.phone_number,
                          log?.status_log?.status?.id
                        )
                      "
                    >
                      <img
                        src="assets/svg/whatsapp.svg"
                        alt="whatsapp icon"
                        width="24px"
                        height="24px"
                      />
                    </button>

                    <mat-icon
                      style="margin-right: 0px"
                      matTooltip="{{ log?.status?.mail_tooltip }}"
                      (click)="
                        sendMatches(
                          8,
                          log?.applier?.id,
                          log?.user?.whatsapp_number ?? log?.user?.phone_number,
                          log?.status_log?.status?.id
                        )
                      "
                      >email</mat-icon
                    >
                    <mat-icon (click)="delete(log.id)">delete</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template #fieldChange>
            <mat-card-content
              *ngIf="log.type === 'CRUD' && log?.details?.changed; else status"
            >
              Der Bewerber wurde bearbeitet
              <ul>
                <ng-container
                  class="changed_field"
                  *ngFor="let change of log?.details?.changed"
                >
                  <li *ngIf="getFieldLabel(change)">
                    <i>
                      {{ getFieldLabel(change) }}
                    </i>
                    von
                    <span
                      *ngIf="lookupFields.includes(change.field); else nolookUp"
                    >
                      "{{
                        change?.old
                          | constantLabel : getLookUpField(change)
                          | async
                      }}" zu "{{
                        change?.new
                          | constantLabel : getLookUpField(change)
                          | async
                      }}"
                    </span>
                    <ng-template #nolookUp>
                      <span>
                        "{{ change?.old ? change?.old : 'keine Angabe' }}" zu "{{
                          change?.new ? change?.new : 'keine Angabe'
                        }}"
                      </span>
                    </ng-template>
                  </li>
                </ng-container>
              </ul>
            </mat-card-content>
          </ng-template>

          <ng-template #status>
            <mat-card-content
              *ngIf="
                log?.old_status !== (log?.status || log?.new_status);
                else default
              "
            >
              {{ statusChangeLabel(log) }}
            </mat-card-content>
          </ng-template>

          <ng-template #default>
            <mat-card-content class="no-margin">
              {{ log.actor | constantLabel : 'actor' | async }}
              {{ log?.created_by?.first_name || log?.user?.first_name }}
              {{ log?.created_by?.last_name || log?.user?.last_name }}

              {{ log?.created_at | date : 'dd.MM.yyyy, HH:mm' }}
            </mat-card-content>
          </ng-template>

          <mat-card-subtitle class="less-margin">
            <span matTooltip="Ablaufdatum" *ngIf="log?.details?.due_date">{{
              log?.details?.due_date | date : 'dd.MM.yyyy, HH:mm'
            }}</span>
          </mat-card-subtitle>

          <mat-card-subtitle class="no-margin">
            <div>
              {{ log?.note }}
            </div>

            <div *ngIf="log?.city || log?.name || log?.position?.length">
              {{ positionLabel(log?.position) }} | {{ log?.city }} |
              {{ log?.name }}
            </div>
          </mat-card-subtitle>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<ng-container *ngIf="!histories">
  <ngx-skeleton-loader
    count="2"
    appearance="line"
    [theme]="{ 'height.px': 100 }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    count="5"
    appearance="line"
    [theme]="{ 'height.px': 50 }"
  ></ngx-skeleton-loader>
</ng-container>
