<app-header
  (isLogin)="checkIsLogin($event)"
  [isLoginPage]="isLoginPage"
></app-header>
<div *ngIf="constantsService.constants | async; else showLoading">
  <router-outlet></router-outlet>
</div>
<ng-template #showLoading>
  <div class="loader-wrapper">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
