<form class="upload-csv__form">
  <div class="upload-csv__company-select">
    <ng-multiselect-dropdown
      class="multiselect-dropdown"
      placeholder="{{ 'analytics.company' | translate }}"
      [settings]="dropdownSettings"
      [data]="dropdownCompanies"
      [(ngModel)]="selectedCompany"
      [ngModelOptions]="{ standalone: true }"
    >
    </ng-multiselect-dropdown>
  </div>

  <div>
    <label for="jobs-upload" class="custom-file-upload">
      Select File(CSV)
    </label>
    <input
      id="jobs-upload"
      type="file"
      class="jobs-input"
      [accept]="'.csv'"
      (change)="onFileSelected($event)"
      #fileUpload
    />
  </div>

  <div class="upload-csv__info">
    <span> 🚨</span>
    <div class="upload-csv__info-text">
      <p>CSV must contain title,description and location</p>
      <div>title,description and location are case sensitive</div>
    </div>
  </div>

  <div class="upload-csv__button">
    <button
      mat-flat-button
      class="btn"
      color="primary"
      [disabled]="isLoading"
      (click)="uploadCSV()"
    >
      <span>Upload CSV</span>
    </button>
  </div>
</form>
