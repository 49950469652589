<mat-drawer-container class="container" [hasBackdrop]="true">
  <!-- Sidenav start -->
  <mat-drawer
    #drawer
    class="sidenav"
    position="end"
    [mode]="'side'"
    (closedStart)="startClose()"
  >
    <mat-tab-group
      animationDuration="0ms"
      [selectedIndex]="selectedTab.selected"
      (selectedIndexChange)="selectedTab.select($event)"
    >
      <mat-tab *ngIf="!isNewJob && !isNewCompany" label="Ausschreibungen">
        <app-job-posting-sidebar
          [postcode]="postcode"
          *ngIf="drawer.opened && selectedTab.isSelected(0)"
          jobId="{{ jobId }}"
        >
        </app-job-posting-sidebar>
      </mat-tab>
      <mat-tab *ngIf="isNewJob || (!isNewJob && !isNewCompany)" label="Job">
        <app-job-sidebar
          *ngIf="drawer.opened && selectedTab?.selected[0] == 1"
          (updateAndClose)="updateAndClose($event)"
          (update)="update($event)"
          (close)="close($event)"
          jobId="{{ jobId }}"
          [dropdownCompanies]="dropdownCompanies"
          companyId="{{ companyId }}"
          isDublicate="{{ isDublicate }}"
          isNewJob="{{ isNewJob }}"
          (isJobUpdated)="checkIsJobUpdated($event)"
        >
        </app-job-sidebar>
      </mat-tab>
    </mat-tab-group>
  </mat-drawer>
  <div>
    <!-- Filter Start  -->
    <div class="row filters-row" style="margin-top: 10px">
      <div class="col-sm-3 filters-col-text filters-col-home">
        <mat-form-field>
          <mat-label translate>{{
            "postiongs.posting_date" | translate
          }}</mat-label>
          <input
            autocomplete="off"
            type="text"
            ngxDaterangepickerMd
            matInput
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [linkedCalendars]="true"
            [showClearButton]="true"
            placeholder="Select please..."
            [autoApply]="false"
            [closeOnAutoApply]="true"
            [(ngModel)]="selectedDateRange"
            (change)="queryBy('date_range', selectedDateRange)"
            [locale]="locale"
            [maxDate]="maxDate"
            [minDate]="minDate"
            [ranges]="ranges"
          />
        </mat-form-field>
      </div>

      <div class="col filters-col-text filters-col-home">
        <mat-form-field>
          <mat-label translate>{{
            "postiongs.Zip_code" | translate
          }}</mat-label>
          <input
            matInput
            [(ngModel)]="search"
            (input)="dataSource.queryBy({ pincode: input.value })"
            placeholder="{{ 'postiongs.Zip_code' | translate }}"
            #input
          />
        </mat-form-field>
      </div>
      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          (onSelect)="queryBy('companies', selectedCompanies)"
          (onDeSelect)="queryBy('companies', selectedCompanies)"
          class="multiselect-dropdown"
          placeholder="{{ 'job_details.company' | translate }}"
          [settings]="dropdownSettings"
          [data]="dropdownCompanies"
          [(ngModel)]="selectedCompanies"
          [ngModelOptions]="{ standalone: true }"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          (onSelect)="queryBy('care_type', selectedCareTypeItems)"
          (onDeSelect)="queryBy('care_type', selectedCareTypeItems)"
          placeholder="{{ 'postiongs.care_type' | translate }}"
          [settings]="dropdownSettings"
          [data]="constants.getList('care_type') | async"
          [(ngModel)]="selectedCareTypeItems"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          (onSelect)="queryBy('templates', selectedTemplateItems)"
          (onDeSelect)="queryBy('templates', selectedTemplateItems)"
          placeholder="{{ 'postiongs.template_name' | translate }}"
          [settings]="dropdownSettings"
          [data]="dropdownTemplateList"
          [(ngModel)]="selectedTemplateItems"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col filters-col filters-col-home">
        <ng-multiselect-dropdown
          #multiSelect
          (onSelect)="queryBy('budget', selectedBudgetItems)"
          (onDeSelect)="queryBy('budget', selectedBudgetItems)"
          placeholder="{{ 'postiongs.budget_filter' | translate }}"
          [settings]="singleSelectionDropdownSettings"
          [data]="constants.getList('budget') | async"
          [(ngModel)]="selectedBudgetItems"
        >
        </ng-multiselect-dropdown>
      </div>
    </div>
    <!--Start with table-->
    <mat-table
      class="mat-elevation-z8"
      mat-table
      multiTemplateDataRows
      matSortDisableClear
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortBy($event)"
      matSortActive="posting_date"
      matSortStart="asc"
    >
      <!--add the company name-->
      <ng-container matColumnDef="companyname">
        <mat-header-cell *matHeaderCellDef mat-sort-header="job__company__name">
          {{ "postiongs.company_name" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.company_name }}
          <!-- <mat-icon class="company-edit" (click)="edit(element?.company_id,element?.job_id,1,0,element?.postcode)">edit</mat-icon> -->
        </mat-cell>
      </ng-container>

      <!--add the job title-->
      <ng-container matColumnDef="PLZ">
        <mat-header-cell *matHeaderCellDef mat-sort-header="postcode">
          {{ "postiongs.Zip_code" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element?.postcode }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="caretype">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="job__care_type"
          translate
        >
          {{ "postiongs.care_type" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element?.care_type?.length > 0">
            <tag *ngFor="let ct of element?.care_type">
              {{ ct | constantLabel : "care_type" | async }}
            </tag>
          </ng-container>
          <ng-container *ngIf="element?.care_type?.length == 0">
            <tag>{{ "message.not_available" | translate }}</tag>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="created">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="posting_date"
          translate
        >
          {{ "postiongs.posting_date" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.posting_date | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="template">
        <mat-header-cell *matHeaderCellDef mat-sort-header="template__name">
          {{ "postiongs.template_name" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.template_name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="budget">
        <mat-header-cell *matHeaderCellDef> Budget </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element | constantLabel : "budget" | async }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" style="text-align: end">
          <mat-icon
            class="action-icon"
            (click)="
              edit(
                element?.company_id,
                element?.job_id,
                0,
                0,
                element?.postcode
              )
            "
            routerLink="/postings/{{ element?.company_id }}/{{
              element?.job_id
            }}/0/0/{{ element?.postcode }}"
            >holiday_village</mat-icon
          >
          <mat-icon
            class="action-icon"
            (click)="
              edit(
                element?.company_id,
                element?.job_id,
                1,
                0,
                element?.postcode
              )
            "
            routerLink="/postings/{{ element?.company_id }}/{{
              element?.job_id
            }}/1/0/{{ element?.postcode }}"
            >edit</mat-icon
          >
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        id="{{ element.id }}_match"
      ></mat-row>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="10">
          {{ "message.no_record" | translate }}
        </td>
      </tr>
    </mat-table>
    <div
      class="mat-elevation-z8 paginator-row"
      *ngIf="dataSource.page$ | async"
    >
      <div class="progress-bar">
        <mat-progress-bar mode="buffer" *ngIf="dataSource.loading$ | async">
        </mat-progress-bar>
      </div>
      <mat-paginator
        *ngIf="dataSource.page$ | async as page"
        [length]="page.totalElements"
        [pageSize]="20"
        [showFirstLastButtons]="true"
        [pageIndex]="page.number"
        [hidePageSize]="true"
        (page)="pageEvent = handlePage($event)"
        (page)="dataSource.fetch($event.pageIndex)"
      >
      </mat-paginator>
    </div>
  </div>

  <div
    *ngIf="(dataSource.loading$ | async) !== false && !curentPage"
    class="skeleton-loader"
  >
    <ngx-skeleton-loader
      count="10"
      appearance="line"
      [theme]="{ 'height.px': 35 }"
    ></ngx-skeleton-loader>
  </div>
</mat-drawer-container>
